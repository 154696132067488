/* @flow */

import { compose } from 'redux';
import moment from 'moment';

import { filterArrivalSites } from './site';
import {
  clearDepartureSlots,
  fetchDepartureSlots,
  clearArrivalSlots,
  fetchArrivalSlots,
} from './slot';
import { estimateBooking } from './estimate';
import { fetchServices } from './service';
import { getArrivalSites, getSiteWithId } from '../reducers/sites';
import type { FormContent } from '../components/Form';
import type { State } from '../reducers';
import type { Site } from '../types';

export const DEPARTURE_CHANGED: string = 'DEPARTURE_CHANGED';
export const ARRIVAL_CHANGED: string = 'ARRIVAL_CHANGED';
export const INVALID_ARRIVAL_TRIP: string = 'INVALID_ARRIVAL_TRIP';

const fetchDepartureSlotsIfRequired = (
  before: FormContent,
  after: FormContent,
) => (dispatch) => {
  if (after.site && after.date) {
    if (before.site !== after.site || before.date !== after.date) {
      dispatch(fetchDepartureSlots(after.site, after.date));
    }
  } else {
    dispatch(clearDepartureSlots());
  }
};

const fetchArrivalSlotsIfRequired = (
  before: FormContent,
  after: FormContent,
) => (dispatch) => {
  if (after.site && after.date) {
    if (before.site !== after.site || before.date !== after.date) {
      dispatch(fetchArrivalSlots(after.site, after.date));
    }
  } else {
    dispatch(clearArrivalSlots());
  }
};

const autoSetArrivalSite = (state: State, departure: FormContent, forceSameSite: boolean) => (
  arrival: FormContent,
) => {
  const newArrival = { ...arrival };
  const sites = getArrivalSites(state);

  const isArrivalSiteValid = arrival.site
    && sites.find((s) => s.id.toString() === arrival.site) != null;

  if (sites.length === 1) {
    newArrival.site = sites[0].id.toString();
  } else if (sites.length > 1) {
    if (isArrivalSiteValid && !forceSameSite) {
      newArrival.site = arrival.site || departure.site;
    } else {
      newArrival.site = departure.site;
    }
  }

  return newArrival;
};

const autoSetCheckpoint = (state: State) => (content: FormContent) => {
  const newContent = { ...content };
  const selectedSite = getSiteWithId(state, content.site);

  if (selectedSite && selectedSite.checkpoints.length === 1) {
    newContent.checkpoint = newContent.checkpoint || selectedSite.checkpoints[0].id.toString();
  }

  return newContent;
};

export const autoSetCheckpointForSite = (sites: Site[]) => (content: FormContent) => {
  const newContent = { ...content };
  const selectedSite = sites.find((s) => s.id.toString() === content.site);

  if (selectedSite && selectedSite.checkpoints.length === 1) {
    newContent.checkpoint = newContent.checkpoint || selectedSite.checkpoints[0].id.toString();
  }

  return newContent;
};

export const autoSetArrivalCheckpoint = (departure: FormContent) => (
  arrival: FormContent,
) => {
  if (departure.checkpoint && !arrival.checkpoint) {
    return {
      ...arrival,
      checkpoint: departure.checkpoint,
    };
  }

  return arrival;
};

const clearCheckpointIfSiteChanged = (before: FormContent) => (after: FormContent) => {
  if (before.site !== after.site) {
    return {
      ...after,
      checkpoint: undefined,
    };
  }
  return after;
};

export const departureChanged = (departure: FormContent) => ({
  type: DEPARTURE_CHANGED,
  departure,
});

export const arrivalChanged = (arrival: FormContent) => ({
  type: ARRIVAL_CHANGED,
  arrival,
});

export const invalidArrivalTrip = () => ({
  type: INVALID_ARRIVAL_TRIP,
});

export const changeDeparture = (departure: FormContent, forceSameSite: boolean) => (
  dispatch,
  getState: () => State,
) => {
  dispatch(filterArrivalSites(departure.site));

  const state = getState();
  const { departure: existingDeparture, arrival } = state.booking;

  const newDeparture = compose(
    autoSetCheckpoint(state),
    clearCheckpointIfSiteChanged(existingDeparture),
  )(departure);

  const currentDate = moment(existingDeparture.date);
  const newDate = moment(newDeparture.date);
  const arrivalDate = moment(arrival.date);

  const hasSiteChanged = existingDeparture.site !== newDeparture.site;
  const hasCheckpointChanged = existingDeparture.checkpoint !== newDeparture.checkpoint;
  const hasDateChanged = !currentDate.isSame(newDate);

  // If the date or the hour has changed, and it's after the current arrival date
  // Then dispatch an event to invalid the arrival trip if there is one
  if (arrivalDate.isBefore(newDate)) {
    dispatch(invalidArrivalTrip());
  } else if (arrivalDate.isSame(newDate)) {
    const newHour = moment(newDeparture.hour, 'HH:mm');
    const arrivalHour = moment(arrival.hour, 'HH:mm');
    if (arrivalHour.isBefore(newHour)) {
      dispatch(invalidArrivalTrip());
    }
  }

  // If the site or the date has changed, then clean the tripDetail stored
  if (hasSiteChanged || hasDateChanged) {
    newDeparture.tripDetail = {};
  }

  // Dispatching departure changed in all case
  dispatch(departureChanged(newDeparture));

  // Trying to set arrival site automatically if departure site changed
  if (hasSiteChanged) {
    // Clear checkpoint if site has changed
    const cleared = {
      ...arrival,
      checkpoint: undefined,
    };

    const newArrival = compose(
      autoSetArrivalCheckpoint(newDeparture),
      autoSetArrivalSite(state, newDeparture, forceSameSite),
    )(cleared);

    dispatch(arrivalChanged(newArrival));
    dispatch(fetchArrivalSlotsIfRequired(arrival, newArrival));
  } else if (hasCheckpointChanged) {
    const newArrival = compose(autoSetArrivalCheckpoint(newDeparture))(arrival);

    dispatch(arrivalChanged(newArrival));
  }

  dispatch(fetchDepartureSlotsIfRequired(existingDeparture, newDeparture));
  dispatch(estimateBooking(true));
  dispatch(fetchServices());
};

export const changeArrival = (arrival: FormContent) => (
  dispatch,
  getState: () => State,
) => {
  const state = getState();
  const { arrival: existingArrival } = state.booking;

  const newArrival = compose(
    autoSetCheckpoint(state),
    clearCheckpointIfSiteChanged(existingArrival),
  )(arrival);

  const currentDate = moment(existingArrival.date);
  const newDate = moment(newArrival.date);

  const hasSiteChanged = existingArrival.site !== newArrival.site;
  const hasDateChanged = !currentDate.isSame(newDate);

  // If the site or the date has changed, then clean the tripDetail stored
  if (hasSiteChanged || hasDateChanged) {
    newArrival.tripDetail = {};
  }

  dispatch(arrivalChanged(newArrival));
  dispatch(fetchArrivalSlotsIfRequired(existingArrival, newArrival));
  dispatch(estimateBooking(true));
  dispatch(fetchServices());
};

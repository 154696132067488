/* @flow */

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import groupBy from 'lodash.groupby';
import { InputSelect } from '@bluevalet/common-ui';

import Classnames from 'classnames';
import { getBusiness, getTravelType } from '../reducers/cart';
import { getCompanies } from '../reducers/business';
import { persistCart, updateTravelType } from '../actions/cart';
import { fetchDetails } from '../actions/information';
import { fetchServices } from '../actions/service';
import { withOrder } from './withOrder';
import { TravelTypes } from '../types';

import type { BusinessCompany } from '../types';

type Props = {
  t: (key: string) => string,
  travelType?: string,
  business?: string,
  companies: Array<BusinessCompany>,
  updateTravelType: (travelType?: string, business?: string) => void,
  persistCart: () => void,
  forceValidation?: boolean,
};

const getCustomer = (companies, id?: number) => (
  companies.find((c) => c.businessCustomerId?.toString() === id?.toString())
);

function TravelType({
  t,
  travelType,
  business,
  companies,
  updateTravelType,
  persistCart,
  forceValidation,
}: Props) {
  const [businessId, setBusinessId] = useState();

  const isTravelTypeChecked = (type) => type === travelType;
  const isCompanyActive = (company: BusinessCompany) => (
    company.businessId.toString() === businessId
  );
  const groupedCompanies = useMemo(() => companies && groupBy(companies, 'businessId'), [companies]);

  useEffect(() => {
    const elem = getCustomer(companies, business);
    setBusinessId(elem?.businessId.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const onTravelTypeChange = (type, businessCustomerId, businessId) => {
    setBusinessId(businessId);
    let worker;
    if (businessId) {
      const elem = getCustomer(companies, businessCustomerId);
      if (elem) {
        worker = {
          id: businessCustomerId,
          name: elem.workerName,
          business: elem.businessName,
          isWorker: elem.isWorker,
        };
      }
    }
    updateTravelType(type, businessCustomerId, worker);
    persistCart([fetchDetails, fetchServices]);
  };

  const onWorkerSelect = (businessCustomerId) => {
    const elem = getCustomer(companies, businessCustomerId);
    setBusinessId(elem.businessId.toString());
    updateTravelType(
      TravelTypes.bvb,
      businessCustomerId,
      {
        id: businessCustomerId,
        name: elem.workerName,
        business: elem.businessName,
        isWorker: elem.isWorker,
      },
    );
    persistCart([fetchDetails, fetchServices]);
  };

  const cls = Classnames('tripsType', {
    error: forceValidation && !travelType,
  });

  return (
    <div className="booking-trip-kind">
      <fieldset id="FieldsetTravelType">
        <legend>{t('booking-trip_type')}</legend>
        <div className="form">
          <div className={cls}>
            <div className="radio">
              <label htmlFor="kind_perso">
                <input
                  type="radio"
                  id="kind_perso"
                  name="travelType"
                  value={TravelTypes.perso}
                  onChange={() => onTravelTypeChange(TravelTypes.perso)}
                  checked={isTravelTypeChecked(TravelTypes.perso)}
                />
                <span className="checkmark" />
                {t('booking-kind-personal')}
              </label>
            </div>

            {(!companies || companies.length === 0) && (
              <div className="radio">
                <label htmlFor="kind_pro">
                  <input
                    type="radio"
                    id="kind_pro"
                    name="travelType"
                    value={TravelTypes.pro}
                    onChange={() => onTravelTypeChange(TravelTypes.pro)}
                    checked={isTravelTypeChecked(TravelTypes.pro)}
                  />
                  <span className="checkmark" />
                  {t('booking-kind-professional')}
                </label>
              </div>
            )}

            {companies &&
              companies.length > 0 &&
              Object.values(groupedCompanies).map((workers, i) => (
                <div className="radio" key={workers[0].businessId}>
                  <label htmlFor={`kind_bvb_${i}`}>
                    <input
                      type="radio"
                      id={`kind_bvb_${i}`}
                      name="business"
                      value={`${TravelTypes.bvb}_${workers[0].businessId}`}
                      onChange={() => onTravelTypeChange(
                        TravelTypes.bvb,
                        workers[0].businessCustomerId.toString(),
                        workers[0].businessId.toString(),
                      )}
                      checked={isCompanyActive(workers[0])}
                    />
                    <span className="checkmark" />
                    <Trans
                      i18nKey="booking-kind-business"
                      values={{ label: workers[0].businessName }}
                    />
                  </label>
                  {workers.length > 1 && (
                    <div className="WorkerSelectorContainer">
                      <div className="WorkerSelectorPrefix">{t('booking-kind-business-book-for')}</div>
                      <div className="WorkerSelector">
                        <InputSelect
                          id={`worker-business-${workers[0].businessId}`}
                          name="worker"
                          value={
                            businessId === workers[0].businessId?.toString() ? business : undefined
                          }
                          onChange={onWorkerSelect}
                          hidePlaceholderOption
                          size="small"
                        >
                          {workers.map((w) => (
                            <option key={w.businessCustomerId} value={w.businessCustomerId}>
                              {w.workerName}
                            </option>
                          ))}
                        </InputSelect>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </fieldset>
    </div>
  );
}

const mapStateToProps = (state) => ({
  travelType: getTravelType(state),
  business: getBusiness(state),
  companies: getCompanies(state),
});

export default compose(
  withOrder,
  connect(mapStateToProps, {
    persistCart,
    updateTravelType,
  }),
  withTranslation(),
)(TravelType);

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { REHYDRATE } from 'redux-persist/lib/constants';
import 'magnific-popup';
import IconChevron from '@bluevalet/react-icons/icon-chevron';

import i18n from '../../i18n';
import StepsMenu from './components/StepsMenu';
import FullBookingSummary from './components/FullBookingSummary';
import ServicesStep from './components/steps/ServicesStep';
import OptionsStep from './components/steps/OptionsStep';
import InformationStep from './components/steps/InformationStep';
import BookingError from './components/BookingError';
import getStore from './store';
import { appendClientIdToForm } from '../tagging';
import {
  handleBookingAction,
  pushCheckoutEvents,
  pushSignInCartEvent,
  pushSignUpCartEvent,
  pushValidateSignUpCartEvent,
} from './tagging';
import { fetchCompanies } from './actions/business';
import { fetchSummary } from './actions/cart';
import { fetchServices } from './actions/service';
import { fetchSites } from './actions/site';
import { fetchSlotsOnBoot } from './actions/slot';
import { StepTypes } from './types';


const getValidateForm = () => $('#ValidateForm');

function onSubmitEvent(state) {
  pushCheckoutEvents(state.cart?.summary);
}

function bindSignIn() {
  const $signIn = $('#LoginForm');
  const $signUp = $('#SignupForm');
  const $signInTab = $('a.sign-in-tab');
  const $signUpTab = $('a.sign-up-tab');

  const activateSignIn = (event) => {
    event.preventDefault();
    $signIn.addClass('active');
    $signUp.removeClass('active');
    $signInTab.addClass('active');
    $signUpTab.removeClass('active');
  };

  const activateSignUp = (event) => {
    event.preventDefault();
    $signIn.removeClass('active');
    $signUp.addClass('active');
    $signInTab.removeClass('active');
    $signUpTab.addClass('active');
    pushSignUpCartEvent();
  };

  $signInTab.on('click', activateSignIn);
  $signUpTab.on('click', activateSignUp);
  $signIn.find('.link a').on('click', activateSignUp);
  $signUp.find('.link a').on('click', activateSignIn);

  $('#CartSignInForm').on('submit', () => {
    pushSignInCartEvent();
  });

  $('#CartSignUpForm').on('submit', () => {
    pushValidateSignUpCartEvent();
  });
}

function bindTagging() {
  appendClientIdToForm(getValidateForm());
}

function bindConfirm(persistedStore) {
  const $form = getValidateForm();

  // Prevent the user from validating an order while the form is already being submitted
  $form.on('submit', () => {
    onSubmitEvent(persistedStore.store.getState());
    $('button').prop('disabled', true);
  });

  // Fix browsing back after submit success
  $(window).on('unload', () => {
    $('button').prop('disabled', false);
  });
}

function onRehydrate(store) {
  store.dispatch(fetchSlotsOnBoot());
  store.dispatch(fetchSites());
  store.dispatch(fetchServices());
  store.dispatch(fetchCompanies());
  store.dispatch(fetchSummary());
}

const bookingMiddleware = (store) => (next) => (action) => {
  next(action);

  // Handling async errors
  switch (action.type) {
    case REHYDRATE:
      onRehydrate(store);
      break;
    default:
      break;
  }

  // Perform tagging based on action
  handleBookingAction(store, action);
};

function renderStepsMenu(step) {
  const root = document.getElementById('StepsMenu');
  if (root) {
    const isError = !!root.getAttribute('data-steps-error');
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <div className="StepsFlowWrapper">
            <div className="StepsMenuBack">
              <IconChevron size={38} onClick={() => window.history.back()} role="button" />
            </div>
            <StepsMenu
              currentStep={step}
              currentStepError={isError}
            />
          </div>
        </I18nextProvider>
      </React.Suspense>,
      root,
    );
  }
}

function clearLocalStorageIfNeeded() {
  const overrideLocalStorageInput = $('input[name="overrideLocalStorage"]');

  const overrideLocalStorage = overrideLocalStorageInput ? overrideLocalStorageInput.val() === 'true' : false;

  if (overrideLocalStorage) {
    localStorage.removeItem('persist:root');
  }
}

function BookingLoader({ title, height }) {
  return (
    <div className="booking bookingStep">
      <h2>{title}</h2>
      <div className="booking-content" style={{ minHeight: `${height}px`, justifyContent: 'center' }}>
        <div className="bv-spinner">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
}

function renderError(persistedStore) {
  const root = document.getElementById('BookingErrorRoot');

  if (root) {
    ReactDOM.render(
      <React.Suspense fallback={null}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <BookingError />
          </PersistGate>
        </Provider>
      </React.Suspense>,
      root,
    );
  }
}

function renderStepServices(persistedStore) {
  const rootStep2 = document.getElementById('ServicesStepRoot');

  if (rootStep2) {
    const title = rootStep2.getElementsByTagName('h2')?.item(0).innerText;
    const loader = (<BookingLoader title={title} height={361} />);
    ReactDOM.render(
      <React.Suspense fallback={loader}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <ServicesStep loader={loader} />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootStep2,
    );
  }
}

function renderStepOptions(persistedStore) {
  const rootStep3 = document.getElementById('OptionsStepRoot');

  if (rootStep3) {
    const title = rootStep3.getElementsByTagName('h2')?.item(0).innerText;
    const loader = (<BookingLoader title={title} height={509} />);
    ReactDOM.render(
      <React.Suspense fallback={loader}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <OptionsStep loader={loader} />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootStep3,
    );
  }
}

function renderStepInformation(persistedStore) {
  const rootStep4 = document.getElementById('InformationStepRoot');

  const $inputs = $('#CurrentCart');
  const forceValidation = $inputs.hasClass('display-error');

  if (rootStep4) {
    const title = rootStep4.getElementsByTagName('h2')?.item(0).innerText;
    ReactDOM.render(
      <React.Suspense fallback={<BookingLoader title={title} height={700} />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <InformationStep forceValidation={forceValidation} />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootStep4,
    );
  }
}

function renderSummary(persistedStore, isPayment, isSticky) {
  const rootSummary = document.getElementById('order-summary');
  if (rootSummary) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <FullBookingSummary
                enableVoucher={isPayment}
                enableServiceToggle={!isPayment}
                isSticky={isSticky}
              />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootSummary,
    );
  }
}

const Step = (step) => {
  // Clear local storage if requested by server
  clearLocalStorageIfNeeded();

  const persistedStore = getStore([bookingMiddleware]);

  // React rendering
  renderStepsMenu(step);
  renderError(persistedStore);

  switch (step) {
    case StepTypes.services:
      renderStepServices(persistedStore);
      break;
    case StepTypes.options:
      renderStepOptions(persistedStore);
      break;
    case StepTypes.information:
      renderStepInformation(persistedStore);
      bindSignIn();
      bindTagging();
      break;
    default: break;
  }

  // UI behaviors
  bindConfirm(persistedStore);
};

export function ServicesCartStep() {
  Step(StepTypes.services);
}
export function OptionsCartStep() {
  Step(StepTypes.options);
}
export function InformationCartStep() {
  Step(StepTypes.information);
}

export function Summary(isPayment: boolean, isSticky: boolean) {
  const persistedStore = getStore();
  renderSummary(persistedStore, isPayment, isSticky);
}

/* @flow */

import API from '../../common/api';
import { executeIfInMode } from './runtime';
import type { State } from '../reducers';
import type { Estimation } from '../types';
import { filterObjectFields, hasEmptyValue, serializeBooking } from '../utils';

export const SUBMIT_ESTIMATE: string = 'SUBMIT_ESTIMATE';
export const ESTIMATE_BOOKING: string = 'ESTIMATE_BOOKING';
export const ESTIMATE_BOOKING_SUCCESS: string = 'ESTIMATE_BOOKING_SUCCESS';
export const ESTIMATE_BOOKING_FAILURE: string = 'ESTIMATE_BOOKING_FAILURE';
export const DISPLAY_FORM_VALIDATION_ERRORS: string = 'DISPLAY_FORM_VALIDATION_ERRORS';

const estimateBookingSuccess = (estimation: Estimation) => ({
  type: ESTIMATE_BOOKING_SUCCESS,
  estimation,
});

const estimateBookingFailure = (error: Error) => ({
  type: ESTIMATE_BOOKING_FAILURE,
  error,
});

const displayFormValidationErrors = (value: boolean) => ({
  type: DISPLAY_FORM_VALIDATION_ERRORS,
  value,
});

const ESTIMATE_REQUEST_FIELDS = ['site', 'date', 'hour'];

const _estimateBooking = (autoEstimate: boolean = false) => (
  dispatch,
  getState: () => State,
) => {
  const state = getState();

  const { isFetching, hasBeenSubmit, estimation, error } = state.estimate;
  if (isFetching) {
    return;
  }

  // Triggered from button click
  if (!autoEstimate) {
    dispatch({
      type: SUBMIT_ESTIMATE,
    });
  }

  const shouldEstimate = !autoEstimate || (autoEstimate && (!!estimation || !!error));

  // Checking booking validity
  const { departure, arrival } = state.booking;
  const departureRequest = filterObjectFields(departure, ESTIMATE_REQUEST_FIELDS);
  const arrivalRequest = filterObjectFields(arrival, ESTIMATE_REQUEST_FIELDS);
  const isValidRequest = (request: Object) => (
    !!request && Object.keys(request).length === ESTIMATE_REQUEST_FIELDS.length &&
    !hasEmptyValue(request)
  );
  if (isValidRequest(departureRequest) && isValidRequest(arrivalRequest) && shouldEstimate) {
    dispatch({
      type: ESTIMATE_BOOKING,
    });

    const { start, end } = serializeBooking(departureRequest, arrivalRequest);

    API.estimate(start, end)
      .then((estimation) => dispatch(estimateBookingSuccess(estimation)))
      .catch((error) => error?.res?.json()
        .then((err) => {
          dispatch(estimateBookingFailure(err?.displayMessage));
        })
        .catch(() => { /* nothing */ }));
  } else {
    const displayErrors = !autoEstimate || hasBeenSubmit || !!estimation;
    dispatch(displayFormValidationErrors(displayErrors));
  }
};

export const estimateBooking = (autoEstimate: boolean = false) => executeIfInMode('estimate', autoEstimate)(_estimateBooking);

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@bluevalet/common-ui';

import { COOKIE_CONSENT_OPEN_EVENT, generateCookieContentState } from './service';
import CookieConsentModal from './CookieConsentModal';

const CONTAINER_ID = 'cookie-consent';


export default function CookieConsent({ display, consent, setConsent, url }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [container, setContainer] = useState(null);
  const { t } = useTranslation();
  const containerClassName = classNames('CookieConsentContainer', {
    CookieConsentDisplay: display,
  });

  useEffect(() => {
    const handler = () => setIsModalOpen(true);
    window.addEventListener(COOKIE_CONSENT_OPEN_EVENT, handler);
    return () => {
      window.removeEventListener(COOKIE_CONSENT_OPEN_EVENT, handler);
    };
  }, []);

  useEffect(() => {
    if (!container) {
      const container = document.createElement('div');
      container.id = CONTAINER_ID;
      setContainer(container);

      document.body.appendChild(container);
    }
  }, [container]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConsentValidateChoice = (consent) => {
    setConsent(generateCookieContentState(consent));
  };

  const handleConsentValidateAllChoice = () => {
    setConsent(
      generateCookieContentState({
        adStorage: true,
        adUserData: true,
        adPersonalization: true,
        analyticsStorage: true,
        functionalityStorage: false,
        personalizationStorage: false,
        securityStorage: false,
      }),
    );
  };

  const handleConsentValidateNoChoice = () => {
    setConsent(
      generateCookieContentState({
        adStorage: false,
        adUserData: false,
        adPersonalization: false,
        analyticsStorage: false,
        functionalityStorage: false,
        personalizationStorage: false,
        securityStorage: false,
      }),
    );
  };

  return (
    <>
      {container !== null && ReactDOM.createPortal(
        <>
          <div className={containerClassName}>
            <div>
              <div className="CookieConsentTitle">
                <span>{t('cookies-consent-popInTitle')}</span>
              </div>
              <p>{t('cookies-consent-popInText')}</p>
            </div>
            <div className="CookieConsentButtons">
              <button type="button" className="personalizedCookieButton" onClick={handleOpenModal}>
                <span>{t('cookies-consent-personalizeLabel')}</span>
              </button>
              <div className="CookieConsentButtonsResponse">
                <Button label={t('cookies-consent-refuseLabel')} onClick={handleConsentValidateNoChoice} small />
                <Button label={t('cookies-consent-acceptLabel')} onClick={handleConsentValidateAllChoice} primary small />
              </div>
            </div>
          </div>
          <CookieConsentModal
            isOpen={isModalOpen}
            validateChoice={handleConsentValidateChoice}
            onRequestClose={handleCloseModal}
            consent={consent}
            url={url}
          />
        </>,
        container,
      )}
    </>
  );
}

/* @flow */

import moment from 'moment';
import API from '../../common/api';
import { executeIfInMode } from './runtime';
import { clearOptions } from './cart';
import type { Service } from '../types';
import type { State } from '../reducers';
import type { FormContent } from '../components/Form';

export const FETCH_SERVICES: string = 'FETCH_SERVICES';
export const FETCH_SERVICES_SUCCESS: string = 'FETCH_SERVICES_SUCCESS';

const fetchServicesSuccess = (services: Array<Service>) => ({
  type: FETCH_SERVICES_SUCCESS,
  services,
});

const canFetchServices = (content: FormContent) => content.site && content.date && content.hour;

const getDateTime = (content: FormContent): ?string => {
  if (content.date && content.hour) {
    const date = moment(content.date).format('DD/MM/YYYY');

    return `${date} ${content.hour}`;
  }

  return null;
};

const _fetchServices = () => (dispatch, getState: () => State) => {
  const state = getState();

  const { isFetching } = state.services;
  if (isFetching) {
    return;
  }

  const { departure, arrival } = state.booking;
  const { business, worker } = state.cart;

  if (canFetchServices(departure) && canFetchServices(arrival)) {
    dispatch({
      type: FETCH_SERVICES,
    });

    const departureDate = getDateTime(departure);
    const arrivalDate = getDateTime(arrival);

    API.getServices(
      departure.site,
      arrival.site,
      departureDate,
      arrivalDate,
      business,
      worker,
    ).then((services) => {
      dispatch(fetchServicesSuccess(services));
    });
  } else {
    dispatch(fetchServicesSuccess([]));
    dispatch(clearOptions());
  }
};

export const fetchServices = () => executeIfInMode('cart')(_fetchServices);

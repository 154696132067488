/* @flow */

import API from '../../common/api';
import type { State } from '../reducers';

export const CLEAR_DEPARTURE_SLOTS: string = 'CLEAR_DEPARTURE_SLOTS';
export const FETCH_DEPARTURE_SLOTS: string = 'FETCH_DEPARTURE_SLOTS';
export const FETCH_DEPARTURE_SLOTS_SUCCESS: string = 'FETCH_DEPARTURE_SLOTS_SUCCESS';
export const CLEAR_ARRIVAL_SLOTS: string = 'CLEAR_ARRIVAL_SLOTS';
export const FETCH_ARRIVAL_SLOTS: string = 'FETCH_ARRIVAL_SLOTS';
export const FETCH_ARRIVAL_SLOTS_SUCCESS: string = 'FETCH_ARRIVAL_SLOTS_SUCCESS';
export const ARRIVAL_SLOT_AVAILABLE: string = 'ARRIVAL_SLOT_AVAILABLE';
export const ARRIVAL_SLOT_UNAVAILABLE: string = 'ARRIVAL_SLOT_UNAVAILABLE';

export const clearDepartureSlots = () => ({
  type: CLEAR_DEPARTURE_SLOTS,
});

const fetchDepartureSlotsSuccess = slots => ({
  type: FETCH_DEPARTURE_SLOTS_SUCCESS,
  slots,
});

const fetchArrivalSlotsSuccess = slots => ({
  type: FETCH_ARRIVAL_SLOTS_SUCCESS,
  slots,
});

export const fetchDepartureSlots = (site: string, date: Date) => (dispatch) => {
  dispatch({
    type: FETCH_DEPARTURE_SLOTS,
  });

  API.getSiteDepartureSlots(site, date).then(slots => dispatch(fetchDepartureSlotsSuccess(slots)));
};

export const fetchArrivalSlots = (site: string, date: Date) => (dispatch) => {
  dispatch({
    type: FETCH_ARRIVAL_SLOTS,
  });

  API.getSiteArrivalSlots(site, date).then(slots => dispatch(fetchArrivalSlotsSuccess(slots)));
};

export const fetchSlotsOnBoot = () => (dispatch, getState: () => State) => {
  const { departure, arrival } = getState().booking;
  if (departure.site && departure.date) {
    dispatch(fetchDepartureSlots(departure.site, departure.date));
  }

  if (arrival.site && arrival.date) {
    dispatch(fetchArrivalSlots(arrival.site, arrival.date));
  }
};

export const clearArrivalSlots = () => ({
  type: CLEAR_ARRIVAL_SLOTS,
});

export const setArrivalSlotAvailable = () => ({
  type: ARRIVAL_SLOT_AVAILABLE,
});

export const setArrivalSlotUnAvailable = () => ({
  type: ARRIVAL_SLOT_UNAVAILABLE,
});

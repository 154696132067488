/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { getError } from '../reducers/cart';

type Props = {
  error?: string,
};

const BookingError = ({ error }: Props) => {
  if (error) {
    return <div className="message alert">{error}</div>;
  }

  return null;
};

const mapStateToProps = (state) => ({
  error: getError(state),
});

export default connect(mapStateToProps, {})(BookingError);

/* @flow */

import { combineReducers } from 'redux';
import booking from './booking';
import sites from './sites';
import estimate from './estimate';
import services from './services';
import cart from './cart';
import business from './business';
import config from './config';
import trip from './trip';
import information from './information';
import type { BookingState } from './booking';
import type { SitesState } from './sites';
import type { EstimateState } from './estimate';
import type { ServicesState } from './services';
import type { CartState } from './cart';
import type { BusinessState } from './business';
import type { ConfigState } from './config';
import type { InformationState } from './information';
import type { TripState } from './trip';

export type State = {
  booking: BookingState,
  sites: SitesState,
  estimate: EstimateState,
  services: ServicesState,
  cart: CartState,
  business: BusinessState,
  config: ConfigState,
  information: InformationState,
  trip: TripState
};

export default combineReducers({
  booking,
  sites,
  estimate,
  services,
  cart,
  business,
  config,
  trip,
  information,
});

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import Modal from 'react-modal';
import i18n from '../../i18n';
import { initGtmConsent } from '../tagging';
import CookieConsentWrapper from './CookieConsentWrapper';
import CookieConsentLink from './CookieConsentLink';


export default function() {
  const root = document.getElementById('CookieConsentRoot');
  if (root) {
    const url = root.getAttribute('data-url');
    Modal.setAppElement('#CookieConsentRoot');
    initGtmConsent();
    ReactDOM.render(
      <React.Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <CookieConsentWrapper url={url} />
        </I18nextProvider>
      </React.Suspense>,
      root,
    );
  }

  const footerLink = document.getElementById('ManageCookies');
  if (footerLink) {
    ReactDOM.render(
      <React.Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <CookieConsentLink />
        </I18nextProvider>
      </React.Suspense>,
      footerLink,
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import ExternalReviews from './components/Reviews';
import i18n from '../../i18n';

export default function ExternalRatings() {
  const externalRatingsRoot = document.getElementById('ExternalRatingsRoot');

  if (externalRatingsRoot) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <ExternalReviews />
        </I18nextProvider>
      </React.Suspense>,
      externalRatingsRoot,
    );
  }
}

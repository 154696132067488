import React, { useReducer } from 'react';

export const updateOrderActions = {
  updateLoading: 'updateLoading',
  updateDepartureDate: 'updateDepartureDate',
  updateDepartureHour: 'updateDepartureHour',
  updateArrivalDate: 'updateArrivalDate',
  updateArrivalHour: 'updateArrivalHour',
  updateArrivalTripNumber: 'updateArrivalTripNumber',
  updateArrivalTripDetails: 'updateArrivalTripDetails',
};

const initUpdateOrder = {
  isLoading: false,
  departure: {
    site: null,
    date: null,
    hour: null,
    tripDetails: null,
  },
  arrival: {
    site: null,
    date: null,
    hour: null,
    tripNumber: null,
    tripDetails: null,
  },
};

const updateOrderReducer = (state, action) => {
  switch (action.type) {
    case updateOrderActions.updateLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case updateOrderActions.updateDepartureDate:
      return {
        ...state,
        departure: {
          ...state.departure,
          date: action.payload,
        },
      };
    case updateOrderActions.updateDepartureHour:
      return {
        ...state,
        departure: {
          ...state.departure,
          hour: action.payload,
        },
      };
    case updateOrderActions.updateArrivalDate:
      return {
        ...state,
        arrival: {
          ...state.arrival,
          date: action.payload,
        },
      };
    case updateOrderActions.updateArrivalHour:
      return {
        ...state,
        arrival: {
          ...state.arrival,
          hour: action.payload,
        },
      };
    case updateOrderActions.updateArrivalTripNumber:
      return {
        ...state,
        arrival: {
          ...state.arrival,
          tripNumber: action.payload,
        },
      };
    case updateOrderActions.updateArrivalTripDetails:
      return {
        ...state,
        arrival: {
          ...state.arrival,
          tripDetails: action.payload,
        },
      };

    default:
      return state;
  }
};

export const UpdateOrderContext = React.createContext();

export const UpdateOrderProvider = ({ initState, children }) => {
  const [state, dispatch] = useReducer(
    updateOrderReducer,
    { ...initUpdateOrder, ...initState, initial: initState },
  );

  return (
    <UpdateOrderContext.Provider value={{ state, dispatch }}>
      {children}
    </UpdateOrderContext.Provider>
  );
};

/* @flow */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { displayDecimal } from '../utils';

type Props = {
  t: (key: string) => string,
  discount?: number,
  isDiscountSitePromotion: Boolean,
};


const SummaryDiscount = ({ t, discount, isDiscountSitePromotion }: Props) => {
  if (discount && !isDiscountSitePromotion) {
    return (
      <div className="business-voucher">
        <div className="voucher-content">
          <span className="code">{t('booking-business_discount')}</span>
          <div className="voucher-price">
            {`- ${displayDecimal(discount)} €`}
          </div>
        </div>
      </div>
    );
  }

  return '';
};

export default withTranslation()(SummaryDiscount);

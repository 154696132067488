/* @flow */

import React from 'react';
import { withTranslation } from 'react-i18next';

import LandingPlaneIcon from '@bluevalet/react-icons/landing-plane';
import TakeOffPlaneIcon from '@bluevalet/react-icons/take-off-plane';
import TrainBackIcon from '@bluevalet/react-icons/train-back';
import TrainGoIcon from '@bluevalet/react-icons/train-go';

import { getSiteWithId, displayDecimal, displayDate, isDisplayablePromotion } from '../utils';
import type { FormContent } from './Form';
import type { Site, OrderSummary } from '../types';

type Props = {
  t: (key: string) => string,
  departure: FormContent,
  arrival: FormContent,
  sites: Array<Site>,
  summary?: OrderSummary,
};

type Travel = {
  t: (key: string) => string,
  sites: Array<Site>,
  departure: FormContent,
  arrival: FormContent,
};

const displayIfPresent = (value) => (children) => (value != null ? children(value) : null);

const SummaryTravel = ({ t, sites, departure, arrival }: Travel) => {
  const departureSite = getSiteWithId(sites, departure.site);
  const arrivalSite = getSiteWithId(sites, arrival.site);

  if (departureSite || arrivalSite) {
    const displayDay = (content: FormContent) => (content.date ? displayDate(content.date) : '-');
    const displayHour = (content: FormContent) => content.hour || '-';

    return (
      <ul className="travel">
        {departureSite && (
          <li className={departureSite.type}>
            {departureSite && arrivalSite && <span className="travel-bar" />}
            {departureSite.type === 'airport' && <TakeOffPlaneIcon size={20} />}
            {departureSite.type === 'station' && <TrainGoIcon size={20} />}
            <span className="travel-name">{departureSite.name}</span>
            <em>
              <strong>{displayDay(departure)}</strong> {t('booking-at')} <strong>{displayHour(departure)}</strong>
            </em>
          </li>
        )}
        {arrivalSite && (
          <li className={arrivalSite.type}>
            {arrivalSite.type === 'airport' && <LandingPlaneIcon size={20} />}
            {arrivalSite.type === 'station' && <TrainBackIcon size={20} />}
            <span className="travel-name">{arrivalSite.name}</span>
            <em>
              <strong>{displayDay(arrival)}</strong> {t('booking-at')} <strong>{displayHour(arrival)}</strong>
            </em>
          </li>
        )}
      </ul>
    );
  }

  return null;
};

const I18nSummaryTravel = withTranslation()(SummaryTravel);

const SummaryPrice = ({ t, departure, arrival, sites, summary }: Props) => {
  const hasSitePromotion = summary && summary.isDiscountSitePromotion;
  const displaySitePromotion =
    hasSitePromotion && isDisplayablePromotion(summary.baseWithoutDiscount, summary.base);
  const getPrice = () => {
    if (hasSitePromotion) {
      const price = displaySitePromotion ? summary.baseWithoutDiscount : summary.base;
      return displayDecimal(price);
    }
    if (summary && !hasSitePromotion) {
      return displayDecimal(summary.baseWithoutDiscount);
    }
    return '--';
  };

  const priceClassName = displaySitePromotion ? 'price-amount without-promotion' : 'price-amount';
  const spanClassName = displaySitePromotion ? 'price-span promotion-span' : 'price-span';

  const PriceWithPromotion = () => (
    <strong className="price-amount promotion">{`${displayDecimal(summary.base)} €`}</strong>
  );

  return (
    <div className="price">
      <span className="parking-title">{t('booking-park_price')}</span>
      <I18nSummaryTravel sites={sites} departure={departure} arrival={arrival} />
      <span className={spanClassName}>
        <strong className={priceClassName}>{`${getPrice()} €`}</strong>
        {displaySitePromotion && <PriceWithPromotion />}
      </span>
      {summary &&
        displayIfPresent(summary.switch)((value) => (
          <span className="switch-price">
            <span className="switch-title">{`+ ${t('booking-switch_price')}`}</span>
            <strong>{`${displayDecimal(value)} €`}</strong>
          </span>
        ))}
    </div>
  );
};

export default withTranslation()(SummaryPrice);

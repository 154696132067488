import $ from 'jquery';

export default function() {
  const $element = $('.homeSlider');

  const defaultHeight = $element.height();

  function toggleFullscreen() {
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();

    // -1 for border
    const headerHeight = $('#Header').height() - 1;

    const dynamicHeight = ((windowHeight - headerHeight) < defaultHeight)
      ? defaultHeight
      : windowHeight - headerHeight;

    $element.height(dynamicHeight).width(windowWidth);
  }

  if ($(window).width() > 480) {
    toggleFullscreen();
  }

  $(window).resize(() => {
    toggleFullscreen();
  });
}

import React from 'react';
import { connect } from 'react-redux';

import Form from './Form';

function Departure(props) {
  return (
    <Form
      prefix="start"
      notice="booking-departure_notice"
      displaySite
      {...props}
    />
  );
}

const mapStateToProps = ({ trip: { start: trip } }) => ({ trip });

export default connect(mapStateToProps)(Departure);

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as Map } from '../../static/images/map.svg';

function enableAccordion() {
  $('.establishment h2').click((evt) => {
    $(evt.currentTarget)
      .parent()
      .toggleClass('open');
  });
}

function displayMap() {
  const mapContainer = document.getElementById(
    'svg-establishments-map-container',
  );

  if (mapContainer) {
    ReactDOM.render(<Map width="100%" height="100%" />, mapContainer);
  }
}

export default function () {
  enableAccordion();
  displayMap();
}

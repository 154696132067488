/* @flow */

import React, { useEffect, useState, Element } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { getError, getSummary, isFetchingSummary } from '../../reducers/cart';
import { getServices, isFetchingServices } from '../../reducers/services';
import { removeOption, persistCart, addOption } from '../../actions/cart';
import AvailableOptions from '../AvailableOptions';
import { NextStepButton } from '../NextStepButton';

import type { OrderSummary, Service } from '../../types';

type Props = {
  loader: Element<any>,
  t: (key: string) => string,
  summary?: OrderSummary,
  removeOption: (service: number) => void,
  services: Array<Service>,
  error?: string,
};

function OptionsStep({
  loader,
  t,
  summary,
  addOption,
  removeOption,
  services,
  error,
  persistCart,
  isFetchingServices,
  isFetchingSummary,
}: Props) {
  const [options, setOptions] = useState(services.filter((service) => service.isInsurance));
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setOptions(services.filter((service) => service.isInsurance));
  }, [services]);

  useEffect(() => {
    setIsDisabled(!summary || (
      !summary.hasCancellationOption
      && !summary.hasBasicOption
      && summary.isAnyInsuranceAvailable
    ));
  }, [summary]);

  const addOptionFn = (e) => {
    addOption(e);
    persistCart(undefined, undefined, true);
  };

  const removeOptionFn = (e) => {
    removeOption(e);
    persistCart(undefined, undefined, true);
  };

  const onDisabledSubmit = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      // Wait for error block to show before scrolling to it
      document.querySelector('.orderOptions .booking-error')
        .scrollIntoView({ block: 'end' });
    }, 200);
  };

  return isFetchingServices || isFetchingSummary ? loader : (
    <div id="order-options" className="orderOptions bookingStep">
      {!error && (
        <>
          <h2>3. {t('booking-options_title')}</h2>
          <div className="booking-content">
            {!isFetchingServices && !isFetchingSummary && (
              <>
                {
                  summary && summary.isAnyInsuranceAvailable ?
                    (
                      <AvailableOptions
                        summary={summary}
                        addOption={addOptionFn}
                        removeOption={removeOptionFn}
                        options={options}
                      />
                    ) : (
                      <div className="no-services">
                        {t('booking-options_no-options-available')}
                      </div>
                    )
                }
              </>
            )}
            {isSubmitted && isDisabled && (
              <div className="booking-error">{t('booking-option-required')}</div>
            )}
          </div>
          <NextStepButton
            label={t('booking-continue')}
            disabled={isDisabled}
            onDisabledClick={onDisabledSubmit}
          />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: getServices(state),
  summary: getSummary(state),
  error: getError(state),
  isFetchingServices: isFetchingServices(state),
  isFetchingSummary: isFetchingSummary(state),
});

export default compose(
  connect(mapStateToProps, {
    addOption,
    removeOption,
    persistCart,
  }),
  withTranslation(),
)(OptionsStep);

/* @flow */

import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Switch, TravelInformations } from '@bluevalet/common-ui';
import { getLang } from 'i18n';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { displayDate, getSiteWithId } from '../utils';
import { persistCart } from '../actions/cart';
import { SiteType } from '../types';
import TripHourPicker from './TripHourPicker';

import type { Site, Slots } from '../types';
import type { FormContent, TripActions } from './Form';

type Props = {
  t: (key: string) => string,
  content: FormContent,
  sites: Array<Site>,
  slots?: Slots,
  forceValidation?: boolean,
  displayNotice?: boolean,
  isFetchingSlots: boolean,
  onChange: (content: FormContent) => void,
  prefix: string, // "start" or "end"
  notice: string,
  trip: any,
  tripActions: TripActions,
  persistCart: () => void,
};

function Trip({
  t,
  content,
  sites,
  slots,
  forceValidation,
  displayNotice,
  isFetchingSlots,
  onChange,
  prefix,
  notice,
  trip,
  tripActions,
  persistCart,
}: Props) {
  const { site, date, hour, tripNumber, tripDetail } = content || {};
  const {
    fetchTripInfos,
    clearTripInfos,
    selectTripInfos,
    forceTripInfos,
    toggleUnknownTripInfos,
  } = tripActions || {};

  const refModalTrip = useRef();
  const [tripController, setTripController] = useState(new AbortController());

  const currentLang = getLang();
  const currentSite = getSiteWithId(sites, site);

  const valueChanged = (name: string) => (value?: string) => {
    onChange({
      ...content,
      [name]: value,
    });
    persistCart();
  };

  const openModal = (modalRef: string) => {
    if (modalRef) {
      modalRef.current.openModal();
    }
  };

  const hideModal = (modalRef: string) => {
    if (modalRef) {
      modalRef.current.closeModal();
    }
  };

  const onChangeTripNumber = (event) => {
    const { value } = event.target;

    tripController.abort();
    const newController = new AbortController();
    setTripController(newController);

    fetchTripInfos(prefix, site, displayDate(date), value, newController.signal);
    onChange({
      ...content,
      tripNumber: value,
      tripDetail: {},
    });
  };

  const onClearTripNumber = () => {
    if (!trip.isUnknown) {
      clearTripInfos(prefix);
      onChange({
        ...content,
        tripNumber: '',
        tripDetail: {},
      });
    }
  };

  const onSelectItemTrip = (item) => {
    if (item !== content.tripDetail) {
      selectTripInfos(prefix, item);
      onChange({
        ...content,
        tripNumber: item.number,
        tripDetail: item,
      });
    }
  };

  const onForceItemTrip = () => {
    forceTripInfos(prefix);
    onChange({
      ...content,
      tripNumber: content.tripNumber.toUpperCase(),
    });
  };

  const onToggleUnknownTrip = () => {
    const tripNumber = !trip.isUnknown ? t('booking-trip_unknown_number_sel') : '';

    toggleUnknownTripInfos(prefix);

    onChange({
      ...content,
      tripNumber,
      tripDetail: {},
    });

    hideModal(refModalTrip);
  };

  const getStatus = () => {
    if (trip.isFetchingInformations) {
      return 'loading';
    }
    if (tripNumber !== '' && !trip.isFetchingInformations && trip.results) {
      return 'loaded';
    }
    return '';
  };

  const getInputProps = () => {
    const hasErrors = forceValidation && !tripNumber;

    return {
      'data-cy': `${prefix}-trip-informations`,
      disabled: !site || !date || trip.isUnknown,
      success: trip.isValid,
      error: hasErrors,
      message: hasErrors ? t('error-required') : '',
      onFocus: () => {
        if (tripNumber !== '' && !trip.isFetchingInformations && !trip.isValid && !trip.isForced) {
          fetchTripInfos(prefix, site, displayDate(date), tripNumber);
        }
      },
    };
  };

  const siteName = currentSite?.name || '';
  const siteType = (currentSite && currentSite.type) || SiteType.Airport;
  const suffix = siteType === SiteType.Station ? 'train' : 'flight';
  const typeTravel = prefix === 'start' ? 'departure' : 'arrival';

  const hourFullLabel =
    prefix === 'start' ? 'booking-hour_start_label' : `booking-hour_end_${suffix}_label`;
  const hourPlaceholder = 'hour';
  const hourLabel = hourFullLabel;

  const tripLabel = `booking-trip_${prefix}_${suffix}_number`;
  const switchLabel = `booking-trip_unknown_${suffix}_number`;
  const unknownTripSelected = 'booking-trip_unknown_number_sel';

  const travelInfosValue = trip.isUnknown ? t(unknownTripSelected) : tripNumber;

  return (
    <div className="Trip">
      <div className="trip-informations">
        <TravelInformations
          type={typeTravel}
          name={`${prefix}TripNumber`}
          label={`${t(tripLabel)} *`}
          site={siteName}
          message={t('booking-trip_travel_not_found')}
          value={travelInfosValue}
          onChange={onChangeTripNumber}
          onClearValue={onClearTripNumber}
          onSelectItem={(item) => onSelectItemTrip(item)}
          forceSelectItem={() => onForceItemTrip()}
          status={getStatus()}
          results={trip.results}
          inputProps={getInputProps()}
          lang={currentLang}
        />

        <Switch
          data-cy={`unknown-trip-${prefix}`}
          id={`unknown-trip-${prefix}`}
          name={`unknown-trip-${prefix}`}
          label={t(switchLabel)}
          onChange={() => {
            if (!trip.isUnknown) {
              openModal(refModalTrip);
            } else {
              onToggleUnknownTrip();
            }
          }}
          checked={trip.isUnknown}
        />
      </div>
      <div className="trip-hour">
        <TripHourPicker
          prefix={prefix}
          date={date}
          hour={hour}
          label={hourLabel}
          placeholder={hourPlaceholder}
          slots={slots}
          tripDetail={tripDetail}
          forceValidation={forceValidation}
          notice={displayNotice ? notice : undefined}
          onHourChange={valueChanged('hour')}
          isFetchingSlots={isFetchingSlots}
          siteType={siteType}
        />
      </div>

      <Modal
        id={`${prefix}TripNumberModal`}
        ref={refModalTrip}
        title={t('booking-trip_unknown_modal_title')}
        content={t('booking-trip_unknown_modal_content')}
        primaryText={t('modal_confirm')}
        secondaryText={t('modal_cancel')}
        onClickPrimary={onToggleUnknownTrip}
        onClickSecondary={() => hideModal(refModalTrip)}
      />
    </div>
  );
}

export default compose(
  connect(() => ({}), { persistCart }),
  withTranslation(),
)(Trip);

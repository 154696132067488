import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { InputSelect } from '@bluevalet/common-ui';

import { isErrorField } from '../utils';
import { Trip } from '../proptypes';

const Dropdown = ({ t, prefix, name, label, items, value, trip, fastBooking, forceValidation, onChange }) => {
  const currentTrip = trip[prefix];

  // Si on n'est pas en "fast booking", alors on check si le voyage a bien été trouvé / forcé
  // et que le point de rendez-vous a été sélectionné
  // TODO: Essayer de valider le checkpoint choisi par le client
  // en fonction du "checkpoint" du trip sélectionné
  const isValidCheckpoint = !fastBooking && value !== '' ? currentTrip.isValid || currentTrip.isForced : false;

  // Récupération de l'item déjà sélectionné
  const selectedItem = items.find((i) => i.id === +value);

  // Si on n'est pas en "fast booking", alors on affiche les erreurs s'il y en a
  const hasErrors = isErrorField(forceValidation, value);
  const inputProps = {
    'data-cy': `${prefix}-checkpoint`,
    success: isValidCheckpoint,
    error: hasErrors,
    message: hasErrors ? t('error-required') : '',
  };

  return (
    <InputSelect
      id={`${prefix}-checkpoint`}
      name={name}
      label={label}
      value={value}
      title={selectedItem && selectedItem.name}
      onChange={onChange}
      placeholder={t('select')}
      {...inputProps}
    >
      {items.map((i) => (
        <option key={i.id} value={i.id}>
          {i.name}
        </option>
      ))}
    </InputSelect>
  );
};

Dropdown.propTypes = {
  t: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  trip: PropTypes.shape(Trip).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  fastBooking: PropTypes.bool,
  forceValidation: PropTypes.bool,
};

Dropdown.defaultProps = {
  value: '',
  fastBooking: false,
  forceValidation: false,
};

const mstp = ({ trip }) => ({ trip });

export default connect(mstp)(withTranslation()(Dropdown));

/* eslint-disable no-new */
import SmartBanner from 'smart-app-banner';

export default () => {
  // const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  // if(!iOS) {
  new SmartBanner({
    daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    // language code for the App Store (defaults to user's browser language)
    // appStoreLanguage: 'us',
    title: 'Bluevalet',
    author: 'Bluevalet',
    button: 'Télécharger',
    store: {
      ios: "l'App Store",
      android: ' Google Play',
    },
    price: {
      ios: 'Gratuit',
      android: 'Gratuit',
    },
    // put platform type ('ios', 'android', etc.) here to force single theme on all device
    theme: 'ios',
    // icon: '' // full path to icon image if not using website icon image
    // force: 'ios', // Uncomment for platform emulation
  });
  // }
};

import React, { useEffect } from 'react';

import { getSiteWithId } from '../utils';
import Dropdown from './Dropdown';

function MeetingPoint({
  label,
  sites,
  content,
  onChanged,
  forceValidation,
}) {
  const fastBooking = false;
  const [siteName, setSiteName] = React.useState('');

  const siteCheckpoints = (site) => {
    if (site && site.length > 0) {
      const target = getSiteWithId(sites, site);
      return target ? target.checkpoints : [];
    }
    return [];
  };

  useEffect(() => {
    const site = getSiteWithId(sites, content.site);
    if (site) {
      setSiteName(site.name);
    }
  }, [sites, content.site]);

  return (
    <div className="MeetingPoint">
      <div className="label">
        {label} *
      </div>
      <div className="selector">
        <div className="site">
          {siteName}
        </div>
        <div className="checkpoint">
          <Dropdown
            name="checkpoint"
            prefix="start"
            items={siteCheckpoints(content?.site)}
            value={content.checkpoint}
            fastBooking={fastBooking}
            forceValidation={forceValidation}
            onChange={(c) => onChanged({
              ...content,
              checkpoint: c,
            })}
          />
        </div>
      </div>
    </div>
  );
}


export default MeetingPoint;

/* @flow */

import $ from 'jquery';

export function pushEvent(name: string, params: Object = {}) {
  try {
    const event = {
      event: name,
      ...params,
    };
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Pushing GTM event:', event);
    }
    window.dataLayer.push(event);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('GTM does not seem to be available.', error);
  }
}

function addToDatalayer() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export function initGtmConsent() {
  addToDatalayer('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied',
  });
}

export function updateGtmConsent(consent) {
  addToDatalayer('consent', 'update', {
    ad_storage: consent.details.adStorage,
    ad_user_data: consent.details.adUserData,
    ad_personalization: consent.details.adPersonalization,
    analytics_storage: consent.details.analyticsStorage,
    functionality_storage: consent.details.functionalityStorage,
    personalization_storage: consent.details.personalizationStorage,
    security_storage: consent.details.securityStorage,
  });
}

const checkIfAnalyticsLoaded = () => new Promise((resolve, reject) => {
  const timeStart = Date.now();
  const TIMEOUT = 3000;

  const _isLoaded = () => {
    if (Date.now() - timeStart > TIMEOUT) {
      reject(new Error('Timeout. Google analytics not injected!'));
      return;
    }
    if (window.ga) {
      resolve(window.ga);
    } else {
      setTimeout(_isLoaded, 500);
    }
  };

  _isLoaded();
});

export function appendClientIdToForm($form: any) {
  if ($form.length === 0) {
    return;
  }

  try {
    checkIfAnalyticsLoaded().then((ga) => {
      ga((tracker) => {
        const clientId = tracker.get('clientId');
        $('<input>')
          .attr({
            type: 'hidden',
            id: 'gaClientId',
            name: 'gaClientId',
            value: clientId,
          })
          .appendTo($form);
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('GA does not seem to be available.', error);
  }
}

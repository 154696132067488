import $ from 'jquery';

const selects = ['#contact-object', '#contact-site'];

const bindEmptyClass = (elem) => {
  if (elem.val() === '') {
    elem.addClass('empty');
  } else {
    elem.removeClass('empty');
  }
};

export default () => {
  const selectElements = selects.map(select => $(select));

  selectElements.forEach((elem) => {
    if (elem) {
      bindEmptyClass(elem);

      elem.change(() => {
        bindEmptyClass($(this));
      });
    }
  });
};

import { InformationState } from '../reducers/information';
import API from '../../common/api';
import { UserInformation } from '../types';

export const UPDATE_INFORMATION: string = 'UPDATE_INFORMATION';
export const FETCH_DETAILS: string = 'FETCH_DETAILS';
export const FETCH_DETAILS_SUCCESS: string = 'FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAILURE: string = 'FETCH_DETAILS_FAILURE';

const fetchDetailsSuccess = (information?: UserInformation) => ({
  type: FETCH_DETAILS_SUCCESS,
  information,
});

const fetchDetailsFailure = (error: Error) => ({
  type: FETCH_DETAILS_FAILURE,
  error,
});

export const updateInformation = (information: InformationState) => ({
  type: UPDATE_INFORMATION,
  information,
});

export const fetchDetails = () => (dispatch) => {
  try {
    API.getDetails()
      .then((res) => {
        if (res.status === 200) {
          res.json().then((details) => dispatch(fetchDetailsSuccess(details)));
        }
      })
      .catch((fetchError) => {
        fetchError.res.json().then((err) => dispatch(fetchDetailsFailure(err)));
      });
  } catch (e) {
    dispatch(fetchDetailsFailure(e));
  }
};

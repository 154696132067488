/* @flow */

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import isEmpty from 'lodash.isempty';
import Classnames from 'classnames';
import PropTypes from 'prop-types';
import TimeIcon from '@bluevalet/react-icons/time';
import { Fixed, InputSelect } from '@bluevalet/common-ui';

import { getAvailableSlot, getAvailableTimesFromSlots } from '../../booking/utils';
import type { Slots } from '../../booking/types';
import { MinWarningDuration } from '../../../conf/domain';
import { dateAndHourToMoment, fromStringToMoment } from '../../../utils/dates';

const moment = extendMoment(Moment);

type HourPickerProps = {
  t: (key: string) => string,
  prefix: String,
  date?: Date,
  hour?: string,
  minHour?: string,
  maxHour?: string,
  tripDetails: PropTypes.object,
  label: string,
  slots?: Slots,
  readOnly?: boolean,
  notice?: string,
  isFetchingSlots: boolean,
  onHourChange: (value?: string) => void,
  siteType: string
};

class HourPicker extends PureComponent {
  static props: HourPickerProps;

  constructor(props) {
    super(props);
    this.refName = `${props.prefix}Input`;
    this[this.refName] = React.createRef();
  }

  componentDidUpdate(prevProps: HourPickerProps) {
    const { slots, date, hour, tripDetails } = this.props;

    if (slots && hour && prevProps.date && date && !moment(prevProps.date).isSame(date)) {
      const displayedSlots = this.getDisplayedSlots();
      const hasSameHourSlot = !!displayedSlots.find((s) => s.value === hour);

      if (!hasSameHourSlot) {
        this.onHourChange('');
      }
    }

    if (this.isArrival() && tripDetails && tripDetails !== prevProps.tripDetails) {
      const slots = this.getDisplayedSlots();
      const findSlot = getAvailableSlot(tripDetails, slots);
      if (!isEmpty(findSlot) && !findSlot.isUnavailable) {
        this.onHourChange(findSlot.value);
      } else {
        this.onHourChange('');
      }
    }
  }

  onHourChange = (value) => {
    const { onHourChange } = this.props;
    onHourChange(value);
  }

  getDisplayedSlots() {
    let displayedSlots = [];
    const { minHour, maxHour, slots, tripDetail, readOnly, date, hour } = this.props;

    if (readOnly && hour) {
      displayedSlots = [{
        slot: dateAndHourToMoment(date, hour),
        value: hour,
      }];
    } else if (slots) {
      let start = slots.startTime;
      let end = slots.endTime;

      if (isEmpty(tripDetail)) {
        if (maxHour) {
          end = maxHour;
        }
        if (minHour) {
          start = minHour;
        }
      } else if (this.isDeparture()) {
        end = moment(tripDetail.departureTime, 'DD/MM/YYYY HH:mm').subtract(slots.step, 'minutes').format('HH:mm');
      } else {
        start = moment(tripDetail.arrivalTime, 'DD/MM/YYYY HH:mm').subtract(slots.step, 'minutes').format('HH:mm');
      }

      start = moment(start, 'HH:mm');
      end = moment(end, 'HH:mm');

      displayedSlots = getAvailableTimesFromSlots(slots, start, end);
    }

    return displayedSlots;
  }

  isDeparture = () => {
    const { prefix } = this.props;
    return prefix === 'start';
  }

  isArrival = () => {
    const { prefix } = this.props;
    return prefix === 'end';
  }

  validateDeparture = () => {
    const { t, date, hour, tripDetails, siteType } = this.props;

    const validation = {
      success: false,
      warning: false,
      error: false,
      message: '',
    };

    if (tripDetails) {
      const selectedDate = fromStringToMoment(date);
      if (hour) {
        const [hours, minutes] = hour.split(':');
        selectedDate.set({ hours, minutes });
      }

      const tripDate = moment(
        tripDetails.departureTime,
        'DD/MM/YYYY HH:mm',
      );

      const warningDuration = MinWarningDuration[siteType];
      if (hour && hour.length > 0) {
        if (selectedDate.isBefore(tripDate, 'minute')) {
          if (selectedDate.diff(tripDate, 'minute') > -warningDuration) {
            validation.warning = true;
            validation.message = t('booking-trip_warning_hours', { n: warningDuration });
          } else {
            validation.success = true;
          }
        } else {
          validation.error = true;
        }
      } else {
        validation.error = true;
      }
    }

    return validation;
  }

  validateArrival = () => {
    const { date, hour, tripDetails } = this.props;

    const validation = {
      success: false,
      warning: false,
      error: false,
      message: '',
    };

    if (tripDetails) {
      const selectedDate = fromStringToMoment(date);
      if (hour) {
        const [hours, minutes] = hour.split(':');
        selectedDate.set({ hours, minutes });
      }

      const tripDate = moment(
        tripDetails.arrivalTime,
        'DD/MM/YYYY HH:mm',
      );

      if (hour && hour.length > 0) {
        if (selectedDate.isSameOrAfter(tripDate)) {
          validation.success = true;
        } else {
          validation.error = true;
        }
      } else if (!hour && tripDetails.arrivalTime) {
        validation.error = true;
      }
    }

    return validation;
  }

  render() {
    const {
      t,
      prefix,
      label,
      hour,
      notice,
      tripDetails,
      readOnly,
      isFetchingSlots,
    } = this.props;

    const times = this.getDisplayedSlots();

    // Checking selected hour is available
    const unavailableHours = times
      .filter((t) => t.isUnavailable)
      .map((t) => t.value);

    const hourValue = unavailableHours.indexOf(hour) === -1 ? hour : null;

    const validation = this.isDeparture() ? this.validateDeparture() : this.validateArrival();

    const hourName = `${prefix}Hour`;

    // If the arrival from transport verification is okay and corresponding slot is available
    //  we switch the select to a display of the hour without user interactions
    const findSlot = tripDetails ? getAvailableSlot(tripDetails, times) : null;
    const isHourForced = this.isArrival() && !isEmpty(findSlot) && !findSlot.isUnavailable;
    const isFixedHour = this.isArrival() && isHourForced && tripDetails;

    const clsHourPicker = Classnames('HourPicker');

    return (
      <div className={clsHourPicker}>
        {
          isFetchingSlots && !isFixedHour && (
            <InputSelect
              id="loading"
              name="loading"
              label={`${t(label)} *`}
              placeholder={t('hour')}
              loading
            />
          )
        }

        {
          isFixedHour && (
            <>
              <Fixed
                dark
                label={`${t(label)} *`}
                value={hour}
              />
              <input type="hidden" name={hourName} value={hour} />
            </>
          )
        }

        {
          !isFetchingSlots && !isFixedHour && (
          <InputSelect
            data-cy={hourName}
            id={hourName}
            name={hourName}
            label={`${t(label)} *`}
            value={hourValue || ''}
            onChange={this.onHourChange}
            hidePlaceholderOption={readOnly}
            placeholder={t('hour')}
            success={validation.success}
            warning={validation.warning}
            error={validation.error}
            message={validation.message}
            icon={<TimeIcon />}
            notice={notice ? {
              question: true,
              content: t(notice),
            } : null}
            readOnly={readOnly}
          >
            {times && times.map((time) => (
              <option
                key={time.value}
                value={time.value}
                disabled={time.isUnavailable}
              >
                {time.isUnavailable
                  ? `${time.value} (${t('full')})`
                  : time.value}
              </option>
            ))}
          </InputSelect>
          )
        }
      </div>
    );
  }
}

export default withTranslation()(HourPicker);

import $ from 'jquery';
import Cookie from 'js-cookie/src/js.cookie';

export default () => {
  if (!Cookie.get('confirm')) {
    $('.cookies').addClass('show');
  }

  $('.cookies .close').bind('click', (e) => {
    e.preventDefault();
    $('.cookies').removeClass('show');
    Cookie.set('confirm', 'true', { expires: 360 });
  });
};

/* @flow */

import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Switch, TravelInformations } from '@bluevalet/common-ui';
import { getLang } from 'i18n';

import useMediaQuery from '../../../plugins/useMediaQuery';
import SiteDropdown from './SiteDropdown';
import Dropdown from './Dropdown';
import DayPicker from './DayPicker';
import HourPicker from './HourPicker';
import { displayDate, getSiteWithId } from '../utils';
import { SiteType } from '../types';

import type { Site, Slots, TripDetail } from '../types';

export type FormContent = {
  site?: string,
  checkpoint?: string,
  date?: Date,
  hour?: string,
  tripNumber?: string,
  tripDetail?: TripDetail,
};

export type TripActions = {
  fetchTripInfos: (
    prefix: string,
    site: string,
    date: string,
    tripNumber: string,
    signal: any,
  ) => void,
  clearTripInfos: (prefix: string) => void,
  selectTripInfos: (prefix: string, details: Object) => void,
  forceTripInfos: (prefix: string) => void,
  toggleUnknownTripInfos: (prefix: string) => void,
};

type Props = {
  t: (key: string) => string,
  content: FormContent,
  sites: Array<Site>,
  slots?: Slots,
  forceValidation?: boolean,
  displaySite: boolean,
  displayNotice?: boolean,
  fastBooking?: boolean,
  isFetchingSlots: boolean,
  onChange: (content: FormContent) => void,
  displayArrivalSiteButton: boolean,
  onDifferentArrivalSiteEnable: () => void,
  prefix: string, // "start" or "end"
  notice: string,
  trip: any,
  actions: TripActions,
};

function GenericForm({
  t,
  content,
  sites,
  slots,
  forceValidation,
  displaySite,
  displayNotice,
  fastBooking,
  isFetchingSlots,
  onChange,
  displayArrivalSiteButton,
  onDifferentArrivalSiteEnable,
  prefix,
  notice,
  trip,
  actions,
}: Props) {
  const { site, checkpoint, date, hour, tripNumber, tripDetail } = content || {};
  const {
    fetchTripInfos,
    clearTripInfos,
    selectTripInfos,
    forceTripInfos,
    toggleUnknownTripInfos,
  } = actions || {};

  const refModalTrip = useRef();
  const isCompactModule = useMediaQuery('(max-width: 960px)');
  const [tripController, setTripController] = useState(new AbortController());

  const currentLang = getLang();
  const currentSite = getSiteWithId(sites, site);

  const valueChanged = (name: string) => (value?: string) => {
    onChange({
      ...content,
      [name]: value,
    });
  };

  const openModal = (modalRef: string) => {
    if (modalRef) {
      modalRef.current.openModal();
    }
  };

  const hideModal = (modalRef: string) => {
    if (modalRef) {
      modalRef.current.closeModal();
    }
  };

  const onChangeTripNumber = (event) => {
    const { value } = event.target;

    tripController.abort();
    const newController = new AbortController();
    setTripController(newController);

    fetchTripInfos(prefix, site, displayDate(date), value, newController.signal);
    onChange({
      ...content,
      tripNumber: value,
      tripDetail: {},
    });
  };

  const onClearTripNumber = () => {
    if (!trip.isUnknown) {
      clearTripInfos(prefix);
      onChange({
        ...content,
        hour: undefined,
        tripNumber: '',
        tripDetail: {},
      });
    }
  };

  const onSelectItemTrip = (item) => {
    if (item !== content.tripDetail) {
      selectTripInfos(prefix, item);
      onChange({
        ...content,
        tripNumber: item.number,
        tripDetail: item,
      });
    }
  };

  const onForceItemTrip = () => {
    forceTripInfos(prefix);
    onChange({
      ...content,
      tripNumber: content.tripNumber.toUpperCase(),
    });
  };

  const onToggleUnknownTrip = () => {
    const tripNumber = !trip.isUnknown ? t('booking-trip_unknown_number_sel') : '';

    toggleUnknownTripInfos(prefix);

    onChange({
      ...content,
      tripNumber,
      tripDetail: {},
    });

    hideModal(refModalTrip);
  };

  const getStatus = () => {
    if (trip.isFetchingInformations) {
      return 'loading';
    }
    if (tripNumber !== '' && !trip.isFetchingInformations && trip.results) {
      return 'loaded';
    }
    return '';
  };

  const getInputProps = () => {
    const hasErrors = forceValidation && !tripNumber;
    const noticeContent = `booking-trip_informations_${prefix}`;

    return {
      'data-cy': `${prefix}-trip-informations`,
      disabled: !site || !date || trip.isUnknown,
      success: trip.isValid,
      error: hasErrors,
      message: hasErrors ? t('error-required') : '',
      onFocus: () => {
        if (tripNumber !== '' && !trip.isFetchingInformations && !trip.isValid && !trip.isForced) {
          fetchTripInfos(prefix, site, displayDate(date), tripNumber);
        }
      },
      notice: {
        content: t(noticeContent),
      },
    };
  };

  const siteCheckpoints = () => {
    if (site && site.length > 0) {
      const target = getSiteWithId(sites, site);
      return target ? target.checkpoints : [];
    }

    return [];
  };

  const siteName = currentSite?.name || '';
  const siteType = (currentSite && currentSite.type) || SiteType.Airport;
  const suffix = siteType === SiteType.Station ? 'train' : 'flight';
  const typeTravel = prefix === 'start' ? 'departure' : 'arrival';

  const siteTitle = `booking-${typeTravel}_site`;

  const prefixDate = fastBooking ? `fast-${isCompactModule ? 'compact' : 'extended'}` : 'full';
  const dateLabel = `booking-${prefixDate}-${prefix}_date`;
  const datePlaceholder = `${dateLabel}_placeholder`;

  const hourFullLabel =
    prefix === 'start' ? 'booking-hour_start_label' : `booking-hour_end_${suffix}_label`;
  const hourPlaceholder = fastBooking && isCompactModule ? hourFullLabel : 'hour';
  const hourLabel = fastBooking && isCompactModule ? null : hourFullLabel;

  const tripLabel = `booking-trip_${prefix}_${suffix}_number`;
  const switchLabel = `booking-trip_unknown_${suffix}_number`;
  const unknownTripSelected = 'booking-trip_unknown_number_sel';

  const travelInfosValue = trip.isUnknown ? t(unknownTripSelected) : tripNumber;

  return (
    <React.Fragment>
      <div className="form">
        {displaySite && (
          <>
            <SiteDropdown
              name="site"
              label={t(siteTitle)}
              prefix={prefix}
              sites={sites}
              value={site}
              fastBooking={fastBooking}
              forceValidation={forceValidation}
              onChange={valueChanged('site')}
            />
            {fastBooking && displayArrivalSiteButton && !!site && (
              <button
                className="arrivalSiteText"
                type="button"
                onClick={() => onDifferentArrivalSiteEnable()}
              >
                {t('booking-arrival_site_modal_title')}
              </button>
            )}
          </>
        )}
        {!fastBooking && (
          <Dropdown
            name="checkpoint"
            label={t('checkpoint')}
            prefix={prefix}
            items={siteCheckpoints()}
            value={checkpoint}
            fastBooking={fastBooking}
            forceValidation={forceValidation}
            onChange={valueChanged('checkpoint')}
          />
        )}
        <DayPicker
          prefix={prefix}
          date={date}
          label={t(dateLabel)}
          placeholder={t(datePlaceholder)}
          tripDetail={tripDetail}
          fastBooking={fastBooking}
          forceValidation={forceValidation}
          onDateChange={valueChanged('date')}
        />
        {!fastBooking && (
          <div className="trip-informations">
            <TravelInformations
              type={typeTravel}
              name={`${prefix}TripNumber`}
              label={t(tripLabel)}
              site={siteName}
              message={t('booking-trip_travel_not_found')}
              value={travelInfosValue}
              onChange={onChangeTripNumber}
              onClearValue={onClearTripNumber}
              onSelectItem={(item) => onSelectItemTrip(item)}
              forceSelectItem={() => onForceItemTrip()}
              status={getStatus()}
              results={trip.results}
              inputProps={getInputProps()}
              lang={currentLang}
            />

            <Switch
              data-cy={`unknown-trip-${prefix}`}
              id={`unknown-trip-${prefix}`}
              name={`unknown-trip-${prefix}`}
              label={t(switchLabel)}
              onChange={() => {
                if (!trip.isUnknown) {
                  openModal(refModalTrip);
                } else {
                  onToggleUnknownTrip();
                }
              }}
              checked={trip.isUnknown}
            />
          </div>
        )}

        <HourPicker
          prefix={prefix}
          date={date}
          hour={hour}
          label={hourLabel}
          placeholder={hourPlaceholder}
          slots={slots}
          tripDetail={tripDetail}
          fastBooking={fastBooking}
          forceValidation={forceValidation}
          notice={displayNotice ? notice : undefined}
          onHourChange={valueChanged('hour')}
          isFetchingSlots={isFetchingSlots}
          siteType={siteType}
        />
      </div>

      <Modal
        id={`${prefix}TripNumberModal`}
        ref={refModalTrip}
        title={t('booking-trip_unknown_modal_title')}
        content={t('booking-trip_unknown_modal_content')}
        primaryText={t('modal_confirm')}
        secondaryText={t('modal_cancel')}
        onClickPrimary={onToggleUnknownTrip}
        onClickSecondary={() => hideModal(refModalTrip)}
      />
    </React.Fragment>
  );
}

export default withTranslation()(GenericForm);

/* @flow */

import {
  FETCH_INFORMATIONS,
  FETCH_INFORMATIONS_SUCCESS,
  SELECT_INFORMATIONS,
  FORCE_INFORMATIONS,
  TOGGLE_UNKNOWN_INFORMATIONS,
  CLEAR_INFORMATIONS,
} from '../actions/trip';
import { INVALID_ARRIVAL_TRIP } from '../actions/booking';
import { Trip } from '../proptypes';

import type { State } from './index';

export type TripState = {
  start: Trip,
  end: Trip,
}

const initialState = {
  start: {
    results: [],
    isFetchingInformations: false,
    isValid: false,
    isForced: false,
    isUnknown: false,
  },
  end: {
    results: [],
    isFetchingInformations: false,
    isValid: false,
    isForced: false,
    isUnknown: false,
  },
};

export default (state: TripState = initialState, action: any) => {
  const { prefix } = action;
  switch (action.type) {
    case FETCH_INFORMATIONS:
      return {
        ...state,
        [prefix]: {
          ...state[prefix],
          isValid: false,
          isForced: false,
          isUnknown: false,
          isFetchingInformations: true,
        },
      };
    case FETCH_INFORMATIONS_SUCCESS:
      return {
        ...state,
        [prefix]: {
          ...state[prefix],
          isFetchingInformations: false,
          results: action.results,
        },
      };
    case SELECT_INFORMATIONS: {
      return {
        ...state,
        [prefix]: {
          ...state[prefix],
          isValid: true,
          isForced: false,
          isUnknown: false,
        },
      };
    }
    case FORCE_INFORMATIONS: {
      return {
        ...state,
        [prefix]: {
          ...state[prefix],
          isValid: false,
          isUnknown: false,
          isForced: true,
        },
      };
    }
    case TOGGLE_UNKNOWN_INFORMATIONS: {
      const {
        [prefix]: { isUnknown },
      } = state;
      return {
        ...state,
        [prefix]: {
          ...state[prefix],
          results: [],
          isValid: false,
          isForced: false,
          isUnknown: !isUnknown,
        },
      };
    }
    case CLEAR_INFORMATIONS: {
      return {
        ...state,
        [prefix]: {
          ...initialState[prefix],
        },
      };
    }
    case INVALID_ARRIVAL_TRIP: {
      return {
        ...state,
        end: {
          ...state.end,
          isValid: false,
        },
      };
    }
    default:
      return state;
  }
};

export const getEndTrip = (state: State) => state.trip.end;

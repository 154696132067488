import React, { useEffect, useState } from 'react';

import { updateGtmConsent } from '../tagging';
import CookieConsent from './CookieConsent';
import { getCookieConsentStateFromCookie, setCookieConsentStateInCookie } from './service';

function setGtmConsent(consent) {
  if (consent?.status) {
    updateGtmConsent(consent);
  }
}

export default function CookieConsentWrapper({ url }) {
  const [consent, setConsent] = useState(null);
  const [cookie, setCookie] = useState(false);

  useEffect(() => {
    if (!cookie) {
      const consentFromCookie = getCookieConsentStateFromCookie();
      setConsent(consentFromCookie);
      setGtmConsent(consentFromCookie);
      setCookie(true);
    }
  }, [cookie]);

  const handleSetConsent = (consent) => {
    setCookieConsentStateInCookie(consent);
    setConsent(consent);
    setGtmConsent(consent);
  };

  return (
    <CookieConsent
      display={consent === null}
      consent={consent}
      setConsent={handleSetConsent}
      url={url}
    />
  );
}

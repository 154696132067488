import PropTypes from 'prop-types';

export const Checkpoint = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export const Site = {
  id: PropTypes.number,
  name: PropTypes.string,
  maxHeight: PropTypes.number,
  checkpoints: PropTypes.arrayOf(PropTypes.shape(Checkpoint)),
  type: PropTypes.string,
};

export const Trip = {
  number: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.object),
  informations: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  ),
  isFetchingInformations: PropTypes.bool,
  isValid: PropTypes.bool,
  isForced: PropTypes.bool,
  isUnknown: PropTypes.bool,
};

/* @flow */

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as SiteActions from '../actions/site';
import type { Site, SiteSwitch } from '../types';

type Props = {
  sites: Array<Site>,
  switches: Array<SiteSwitch>,
  arrivalSites: Array<Site>,
  fetchSites: () => void,
};

const mapStateToProps = state => ({
  ...state.sites,
});

const mapDispatchToProps = dispatch => bindActionCreators(SiteActions, dispatch);

export const withSites = (WrappedComponent: ReactClass<*>) => {
  class WithSites extends PureComponent {
    static props: Props;

    static displayName = 'HOC(withSites)';

    componentDidMount() {
      const { fetchSites } = this.props;
      fetchSites();
    }

    render() {
      const { sites, switches, arrivalSites } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          sites={sites}
          switches={switches}
          arrivalSites={arrivalSites}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithSites);
};

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import ExternalReviews from './common/components/Reviews';

export default function() {
  const root = document.getElementById('footerReviewContainer');

  if (root) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <ExternalReviews />
        </I18nextProvider>
      </React.Suspense>,
      root,
    );
  }
}

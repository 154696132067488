/* @flow */

import { getBooking } from '../reducers/booking';
import { getSites } from '../reducers/sites';
import { ESTIMATE_BOOKING_FAILURE, ESTIMATE_BOOKING_SUCCESS } from '../actions/estimate';
import { pushEstimationErrorEvents, pushEstimationEvents } from '../tagging';

export default (store) => (next) => (action) => {
  next(action);

  const state = store.getState();
  const sites = getSites(state);
  const booking = getBooking(state);

  switch (action.type) {
    case ESTIMATE_BOOKING_SUCCESS:
      pushEstimationEvents(sites, action.estimation, booking);
      break;
    case ESTIMATE_BOOKING_FAILURE:
      pushEstimationErrorEvents(sites, booking);
      break;
    default:
      break;
  }
};

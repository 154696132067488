/* @flow */

import API from '../../common/api';
import type { Site } from '../types';
import type { State } from '../reducers';

export const FETCH_SITES: string = 'FETCH_SITES';
export const FETCH_SITES_SUCCESS: string = 'FETCH_SITES_SUCCESS';
export const ARRIVAL_SITES_FILTERED: string = 'ARRIVAL_SITES_FILTERED';

const fetchSitesSuccess = ({ sites, switches }) => ({
  type: FETCH_SITES_SUCCESS,
  sites,
  switches,
});

const arrivalSitesFiltered = (sites: Array<Site>) => ({
  type: ARRIVAL_SITES_FILTERED,
  sites,
});

export const filterArrivalSites = (departure?: string) => (
  dispatch,
  getState: () => State,
) => {
  const { sites, switches } = getState().sites;

  if (departure) {
    const existSwitchWith = (site: Site) => {
      const siteSwitch = switches.find(s => (
        s.from.toString() === departure && s.to.toString() === site.id.toString()
      ));
      return siteSwitch !== undefined;
    };

    const isDeparture = (site: Site) => site.id.toString() === departure;

    // Filtering arrival sites based on departure and existing switches
    const arrivalSites = sites.filter(site => existSwitchWith(site) || isDeparture(site));

    dispatch(arrivalSitesFiltered(arrivalSites));
  } else {
    dispatch(arrivalSitesFiltered(sites));
  }
};

const filterArrivalSitesOnceFetched = () => (
  dispatch,
  getState: () => State,
) => {
  const { booking: { departure } } = getState();
  if (departure && departure.site) {
    dispatch(filterArrivalSites(departure.site));
  }
};

export const fetchSites = () => (dispatch, getState: () => State) => {
  const { isFetchingSites } = getState().sites;
  if (isFetchingSites) {
    return;
  }

  dispatch({
    type: FETCH_SITES,
  });

  API.getSites().then((res) => {
    dispatch(fetchSitesSuccess(res));
    dispatch(filterArrivalSitesOnceFetched());
  });
};

import $ from 'jquery';
import { mapStateFromForm } from './utils';
import createStore from './createPersistedStore';

let store = null;

export default function getStore(middlewares) {
  if (!store) {
    const $inputs = $('#CurrentCart');
    const initialState = mapStateFromForm($inputs);

    store = createStore('cart', initialState, middlewares);
  }
  return store;
}

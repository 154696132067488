import $ from 'jquery';

export default () => {
  $('form input[type="checkbox"]').on('change', (evt) => {
    const $element = $(evt.currentTarget);

    if (evt.currentTarget.checked) {
      $element.val('true');
    } else {
      $element.val('false');
    }
  });
};

/* @flow */

import type { State } from './index';
import type { ConfigMode } from '../types';

export type ConfigState = {
  mode: ConfigMode,
};

const initialState = {
  mode: 'cart',
};

export default (state: ConfigState = initialState) => state;

export const getMode = (state: State) => state.config.mode;
export const isEstimateMode = (state: State) => state.config.mode === 'estimate';
export const isCartMode = (state: State) => state.config.mode === 'cart';

/* @flow */

import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-xhr-backend'; // fallback xhr load
import moment from 'moment';

const debug = process.env.NODE_ENV === 'development';

export const getLang = () => i18next.language;

i18next
  .use(Backend)
  .use(LngDetector)
  .init({
    fallbackLng: 'fr',
    debug,
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'span', 'strong', 'b'],
    },
    detection: {
      order: ['cookie', 'navigator'],
      lookupCookie: 'PLAY_LANG',
    },
    backend: {
      backends: [
        LocalStorageBackend, // primary
        XHR, // fallback
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'bv_i18n_',

          // expiration
          expirationTime: 1,

          // language versions
          versions: {
            fr: 'v4',
            en: 'v4',
            es: 'v4',
            nl: 'v4',
            it: 'v4',
          },
        },
        {
          withCredentials: true,
          allowMultiLoading: false,
          loadPath: '/locales/{{lng}}.json',
        },
      ],
    },
  })
  .then(() => {
    moment.locale(getLang());
  });

export default i18next;

import React from 'react';
import { withTranslation } from 'react-i18next';
import LocationBlue from '@bluevalet/react-icons/location-blue';
import EuroBlue from '@bluevalet/react-icons/euro-blue';
import Time from '@bluevalet/react-icons/time';

function LandingSiteInfo({ t, siteCode }) {
  const infos = [
    {
      title: t('landing-bubble_info_price_title'),
      icon: EuroBlue,
      content: t(`landing-bubble_info_${siteCode}_price_content`),
    },
    {
      title: t('landing-bubble_info_location_title'),
      icon: LocationBlue,
      content: t(`landing-bubble_info_${siteCode}_location_content`),
    },
    {
      title: t('landing-bubble_info_openingTime_title'),
      icon: Time,
      content: t(`landing-bubble_info_${siteCode}_openingTime_content`),
    },
  ];

  return (
    <div className="boxesInfo">
      {
        infos.map((info) => {
          const Icon = info.icon;
          return (
            <div key={info.title} className="box">
              <Icon />
              <div className="text-container">
                <p className="title">{info.title}</p>
                <p>{info.content}</p>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

export default withTranslation()(LandingSiteInfo);

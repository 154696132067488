import $ from 'jquery';
import { pushNewsletterEvent } from './booking/tagging';

export default function() {
  $('.NewsletterForm').on('submit', async (event) => {
    const from = event.target?.getAttribute('data-from');
    pushNewsletterEvent(from);
    event.preventDefault();
    await window.grecaptcha.enterprise.ready(async() => {
      const token = await window.grecaptcha.enterprise.execute(window.RECAPTCHA_API_KEY, { action: 'newsletter' });
      $(`.NewsletterForm[data-from="${from}"] input[name="token"]`).val(token);
      event.currentTarget.submit();
    });
  });
}

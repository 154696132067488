import $ from 'jquery';

export default () => {
  const header = document.querySelector('header');
  const nav = document.querySelector('nav');
  const hamburger = document.getElementById('hamburger');

  if (header && nav && hamburger) {
    hamburger.addEventListener('click', (e) => {
      e.stopPropagation();
      $(header).toggleClass('nav-open');
      $(nav).toggleClass('show');
    });

    $('#Header nav > ul > li.with-arrow > a').click((e) => {
      if ($(window).width() < 960) {
        e.stopPropagation();
        e.preventDefault();

        $(e.currentTarget)
          .next()
          .toggleClass('show');
      }
    });

    $('#Header nav > ul').click((e) => {
      e.stopPropagation();
    });

    $(window).click(() => {
      if ($(nav).hasClass('show')) {
        $(nav).removeClass('show');
      }
    });
  }
};

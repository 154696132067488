/* @flow */

import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Classnames from 'classnames';
import { ButtonIcon, ModalService, Tooltip } from '@bluevalet/common-ui';
import ChevronIcon from '@bluevalet/react-icons/icon-chevron';
import CloseIcon from '@bluevalet/react-icons/icon-close';
import InsuranceFull from '@bluevalet/react-icons/insurance-full';
import InsuranceNone from '@bluevalet/react-icons/insurance-none';
import TickIcon from '@bluevalet/react-icons/icon-tick';
import { OptionTypes } from '../types';

type Props = {
  addOption: (id: number, previous: boolean) => void,
  removeOption: (id: number) => void,
  optionType: String,
  price: Number,
  hasOption: Boolean,
  isRecommended: Boolean,
};

const AvailableOption = ({
  addOption,
  removeOption,
  optionType,
  price,
  hasOption,
  isRecommended,
  t,
}: Props) => {
  const modalRef = useRef(null);

  const openServiceModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    modalRef.current.openModal();
  };

  const toggleServiceLabel = hasOption
    ? t('booking-services_remove_service')
    : t('booking-services_add_service');

  const getIcon = (optionType) => (optionType === OptionTypes.premium ? (
    <InsuranceFull size={45} />
  ) : (
    <InsuranceNone size={45} />
  ));

  const hideButton = optionType === OptionTypes.basic && hasOption;

  return (
    <div
      className={Classnames('service', { selected: hasOption })}
      onClick={addOption}
      role="button"
      tabIndex="0"
      aria-pressed={hasOption}
    >
      <div className="informations">
        {getIcon(optionType)}
        <div className="content">
          <h4 data-cy={`option-${optionType}-title`}>{t(`booking-options_${optionType}`)}</h4>
          {isRecommended && <Tooltip label={`⭐ ${t('tooltip-recommanded')}`} type="blue" />}
          <div className="details">{t(`booking-options_${optionType}_description`)}</div>
          <div role="presentation" className="see-more" onClick={openServiceModal}>
            <span>{t('booking-know_more')}</span>
            <ChevronIcon size={16} />
          </div>
        </div>
      </div>
      <div className="choose">
        <span className="price" data-cy={`option-${optionType}-price`}>
          {price}
        </span>
        {hasOption ? (
          <ButtonIcon data-cy={`option-${optionType}-selected-button`}>
            <TickIcon size={18} width="100%" />
          </ButtonIcon>
        ) : (
          <ButtonIcon data-cy={`option-${optionType}-unselected-button`}>
            <CloseIcon size={18} width="100%" />
          </ButtonIcon>
        )}
      </div>
      <ModalService
        ref={modalRef}
        icon={getIcon(optionType)}
        name={t(`booking-options_${optionType}`)}
        price={price}
        description={t(`booking-options_${optionType}_know_more`)}
        buttonProps={
          !hideButton && {
            label: toggleServiceLabel,
            handleClick: () => {
              modalRef.current.closeModal();
              return hasOption ? removeOption?.() : addOption();
            },
          }
        }
      />
    </div>
  );
};

export default withTranslation()(AvailableOption);

/* @flow */

import {
  FETCH_SITES,
  FETCH_SITES_SUCCESS,
  ARRIVAL_SITES_FILTERED,
} from '../actions/site';
import type { Site, SiteSwitch } from '../types';
import type { State } from './index';

export type SitesState = {
  isFetchingSites: boolean,
  sites: Array<Site>,
  arrivalSites: Array<Site>,
  switches: Array<SiteSwitch>,
};

const initialState = {
  isFetchingSites: false,
  sites: [],
  switches: [],
  arrivalSites: [],
};

export default (state: SitesState = initialState, action: any) => {
  switch (action.type) {
    case FETCH_SITES:
      return {
        ...state,
        isFetchingSites: true,
      };
    case FETCH_SITES_SUCCESS:
      return {
        ...state,
        sites: action.sites,
        switches: action.switches,
        arrivalSites: action.sites,
        isFetchingSites: false,
      };
    case ARRIVAL_SITES_FILTERED:
      return {
        ...state,
        arrivalSites: action.sites,
      };
    default:
      return state;
  }
};

export const getSites = (state: State): Array<Site> => state.sites.sites;
export const getArrivalSites = (state: State): Array<Site> => state.sites.arrivalSites;
export const getSiteWithId = (state: State, id?: string): Site => (
  state.sites.sites.find(s => s.id.toString() === id)
);

import React from 'react';
import { withTranslation } from 'react-i18next';
import RatingStars from '../../reviews/components/RatingStars';
import { getExternalReviewsData } from '../../../utils/ratingReviews';

const Reviews = ({ t }) => {
  const externalReviewsData = getExternalReviewsData();
  return (
    <div className="reviewsContainer">
      {externalReviewsData.map((review, i) => (
        <a key={i} target="blank" href={review.link}>
          <div className="review">
            <div className="ratingContainer">
              <p className="rateAmount">{review.rate}</p>
              <RatingStars rating={review.rate} />
            </div>
            <p className="totalReviews">
              +{review.amount} {t('reviews')} <span>{review.name}</span>
            </p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default withTranslation()(Reviews);

import $ from 'jquery';

const $formReview = $('#review-form');
const $modal = $('#share-review');
const $commentReview = $('textarea[name="description"]');
const $btnSubmitReview = $('#submit-review');
const $btnCopyComment = $('#btn-copy-comment');

function handleSubmit(event) {
  event.preventDefault();

  const formValues = $formReview.serializeArray();
  const score = formValues.find(data => data.name === 'score');
  if (score.value === '5') {
    $.magnificPopup.open({
      items: {
        src: $modal,
      },
      closeOnBgClick: false,
    });
  } else {
    $formReview.submit();
  }
}

function handleCopyComment(event) {
  event.preventDefault();
  const $temp = $('<input>');
  $modal.append($temp);
  $temp.val($commentReview.val());
  $temp.select();
  document.execCommand('copy');
  $temp.remove();
  $btnCopyComment.addClass('hidden');
  $('.comment-copied').removeClass('hidden');
}

function submitReviewForm() {
  setTimeout(() => {
    $.magnificPopup.close();
    $formReview.submit();
  }, 100);
}

function bindScore(selector) {
  const $container = $formReview.find(selector);
  const $input = $container.find('input');

  const setInputValue = value => $input.val(value);

  const setElementActive = ($element) => {
    $element.addClass('active');
    $container
      .find('li')
      .not($element)
      .removeClass('active');
  };

  $container.find('li').on('click', (event) => {
    const $star = $(event.currentTarget);
    const value = $star.attr('data-value');

    setInputValue(value);
    setElementActive($star);
  });
}

function bindSource() {
  const $source = $formReview.find('.how-you-know');
  const $input = $source.find('input[type="hidden"]');

  const setInputValue = value => $input.val(value);

  const setElementActive = ($element) => {
    $source
      .find('input[type="checkbox"]')
      .not($element)
      .prop('checked', false);
  };

  $source.find('input[type="checkbox"]').on('change', (event) => {
    const $element = $(event.currentTarget);
    if (event.currentTarget.checked) {
      setElementActive($element);
      setInputValue(event.currentTarget.id);
    } else {
      setInputValue(undefined);
    }
  });
}

export default function () {
  bindScore('.stars');
  bindScore('.eval');
  bindSource();

  $btnSubmitReview.on('click', handleSubmit);
  $btnCopyComment.on('click', handleCopyComment);
  $modal.on('click', '.submit .Link', submitReviewForm);
}

import { SiteType } from '../modules/booking/types';

export const MinWarningDuration = {
  [SiteType.Airport]: 45,
  [SiteType.Station]: 30,
};

export const promotionDisplayRules = {
  minPromotionAmount: 3,
  minPromotionCoeff: 0.04,
};

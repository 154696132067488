import page from 'page';

/* Landings */
import SiteReviews from 'modules/landings/siteReviews';
import SiteInfo from './modules/landings/SiteInfo';

/* Common */
import SiteCards from './modules/landingAiports/sites';
import SitesSlider from './modules/common/sitesSlider';
import ExternalRatings from './modules/common/externalRatings';

/* Homepage */
import Home from './modules/home';
import Fast from './modules/booking/fast';
import Establishment from './modules/home/establishment';

/* Booking, Payment */
import {
  InformationCartStep,
  OptionsCartStep,
  ServicesCartStep,
  Summary,
} from './modules/booking/full';
import { Payment, WaitingPayment, PaymentFailure, PaymentSuccess } from './modules/booking/payment';

/* Faq Accordion */
import FaqAccordion from './modules/common/faqAccordion';

/* Affiliate */
import Affiliate from './modules/affiliate';

/* Reviews */
import Reviews from './modules/reviews';
import NewReview from './modules/reviews/create';

/* Profile */
import Profile from './modules/account/profile';

/* Order */
import Order from './modules/account/order';

const renderHome = () => {
  Home();
  Fast();
  ExternalRatings();
  Establishment();
  SitesSlider('sites-slider-container');
  FaqAccordion();
};

const renderParkingLandingPage = () => {
  Home();
  Fast();
  SiteInfo();
  ExternalRatings();
  SiteReviews();
  FaqAccordion();
};

const renderAdsLandingPage = () => {
  Fast();
  SiteInfo();
  FaqAccordion();
  ExternalRatings();
};

const renderLandingPageAirports = () => {
  Fast();
  SiteCards();
};

const renderLongStayLandingPage = () => {
  SiteReviews();
};

const renderOfferParking = () => {
  Fast();
  ExternalRatings();
};

const renderServicesStep = () => {
  ServicesCartStep();
  Summary(false, true);
};

const renderOptionsStep = () => {
  OptionsCartStep();
  Summary(false, true);
};

const renderInformationStep = () => {
  InformationCartStep();
  Summary(false, true);
};

const renderPayment = () => {
  Payment();
  Summary(true, false);
};

const renderWaiting = () => {
  WaitingPayment();
};

const renderPaymentSuccess = () => {
  PaymentSuccess();
};

const renderPaymentFailure = () => {
  PaymentFailure();
};

const promoCode = () => {
  FaqAccordion();
};

const renderAffiliate = () => {
  Affiliate();
};

const renderReviews = () => {
  Reviews();
  SitesSlider('SitesSliderRoot');
};

const renderNewReview = () => {
  NewReview();
};

const renderProfile = () => {
  Profile();
};

const renderOrder = () => {
  Order();
};

export default () => {
  page('/fr', renderHome);
  page('/en', renderHome);
  page('/es', renderHome);
  page('/nl', renderHome);
  page('/pt', renderHome);
  page('/it', renderHome);
  page('/:lang/landing/*/parking-longue-duree', renderLongStayLandingPage);
  page('/:lang/landing/*', renderParkingLandingPage);
  page('/:lang/ads/*', renderAdsLandingPage);
  page('/:lang/parking-aeroport', renderLandingPageAirports);
  page('/:lang/parking-voiturier-aeroport', renderOfferParking);

  // page('/:lang/cart', renderBooking);
  page('/:lang/cart/services', renderServicesStep);
  page('/:lang/cart/options', renderOptionsStep);
  page('/:lang/cart/info', renderInformationStep);
  page('/:lang/cart/signin', renderInformationStep); // on error only
  page('/:lang/cart/signup', renderInformationStep); // on error only
  page('/:lang/cart/payment', renderPayment);
  page('/:lang/cart/payment/waiting', renderWaiting);
  page('/:lang/cart/:id/success', renderPaymentSuccess);
  page('/:lang/cart/:id/error', renderPaymentFailure);

  page('/:lang/code-promo', promoCode);

  page('/:lang/reviews', renderReviews);
  page('/:lang/reviews/new', renderNewReview);

  page('/:lang/affiliate', renderAffiliate);

  page('/:lang/my-account/profile', renderProfile);

  page('/:lang/orders/:id', renderOrder);

  page.start({
    click: false,
    popstate: false,
  });
};

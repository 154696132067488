import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from '@bluevalet/common-ui';
import RatingStars from '../reviews/components/RatingStars';
import { ReactComponent as AirportIcon } from '../../static/images/icons/airport.svg';
import { ReactComponent as StationIcon } from '../../static/images/icons/station.svg';

import bdxMerignacImg from '../../static/images/sites/bdx-merignac.webp';
import bdxStJeanImg from '../../static/images/sites/bdx-stJean.webp';
import beauvaisImg from '../../static/images/sites/beauvais-airport.webp';
import lyonAirportImg from '../../static/images/sites/lyon-stEx.webp';
import lyonStationImg from '../../static/images/sites/lyon-stEx-station.webp';
import mrsProvenceImg from '../../static/images/sites/mrs-provence.webp';
import aixProvenceStationImg from '../../static/images/sites/aix-provence-station.webp';
import nantesImg from '../../static/images/sites/nantes.webp';
import niceImg from '../../static/images/sites/nice.webp';
import parisCdgImg from '../../static/images/sites/paris-cdg.webp';
import parisOrlyImg from '../../static/images/sites/paris-orly.webp';
import tlsBlagnacImg from '../../static/images/sites/toulouse-blagnac.webp';

export const data = [
  {
    siteKey: 'BOD',
    siteName: 'Bordeaux Mérignac',
    rating: 4.7,
    type: 'airport',
    img: { src: bdxMerignacImg, height: 450, width: 307 },
    url: 'bordeaux-airport',
  },
  {
    siteKey: 'BODG',
    siteName: 'Bordeaux Saint-Jean',
    rating: 4.7,
    type: 'station',
    img: { src: bdxStJeanImg, height: 450, width: 307 },
    url: 'bordeaux-station',
  },
  {
    siteKey: 'ORY',
    siteName: 'Paris Orly',
    rating: 4.5,
    type: 'airport',
    img: { src: parisOrlyImg, height: 450, width: 307 },
    url: 'orly-airport',
  },
  {
    siteKey: 'CDG',
    siteName: 'Paris Charles de Gaulle',
    rating: 4.8,
    type: 'airport',
    img: { src: parisCdgImg, height: 450, width: 307 },
    url: 'roissy-airport',
  },
  {
    siteKey: 'LYS',
    siteName: 'Lyon Saint Exupéry',
    rating: 4.7,
    type: 'airport',
    img: { src: lyonAirportImg, height: 450, width: 307 },
    url: 'lyon-airport',
  },
  {
    siteKey: 'LYSG',
    siteName: 'Lyon Saint Exupéry',
    rating: 4.7,
    type: 'station',
    img: { src: lyonStationImg, height: 450, width: 307 },
    url: 'lyon-station',
  },
  {
    siteKey: 'TLS',
    siteName: 'Toulouse Blagnac',
    rating: 4.8,
    type: 'airport',
    img: { src: tlsBlagnacImg, height: 450, width: 307 },
    url: 'toulouse-airport',
  },
  {
    siteKey: 'MRS',
    siteName: 'Marseille Provence',
    rating: 4.7,
    type: 'airport',
    img: { src: mrsProvenceImg, height: 450, width: 307 },
    url: 'marseille-airport',
  },
  {
    siteKey: 'AIX',
    siteName: 'Aix-en-Provence',
    rating: 4.8,
    type: 'station',
    img: { src: aixProvenceStationImg, height: 450, width: 307 },
    url: 'aix-station',
  },
  {
    siteKey: 'NCE',
    siteName: 'Nice',
    rating: 4.8,
    type: 'airport',
    img: { src: niceImg, height: 450, width: 307 },
    url: 'nice-airport',
  },
  {
    siteKey: 'NTE',
    siteName: 'Nantes',
    rating: 4.7,
    type: 'airport',
    img: { src: nantesImg, height: 450, width: 307 },
    url: 'nantes-airport',
  },
  {
    siteKey: 'BVA',
    siteName: 'Paris Beauvais',
    rating: 4.7,
    type: 'airport',
    img: { src: beauvaisImg, height: 460, width: 325 },
    url: 'beauvais-airport',
  },
];

function SiteCard({ t, i18n, siteKey, customClass = '', withLink = true }) {
  const buildUrl = (url) => `/${i18n.language}/landing/${url}`;

  const site = data.find((d) => d.siteKey === siteKey);

  if (site) {
    const { siteName, type, rating, img, url } = site;

    const content = (
      <>
        <img
          height={img.height}
          width={img.width}
          loading="lazy"
          className="backgroundImage"
          src={img.src}
          alt={`${siteName} ${type}`}
        />
        <div className="card">
          <div className={`iconContainer ${type}`}>
            {type === 'station' ? <StationIcon /> : <AirportIcon />}
          </div>
          <div className="itemsContainer">
            <div className="ratingContainer">
              <p className="rateAmount">{`${rating}/5`}</p>
              <div className="starsContainer">
                <RatingStars rating={rating} />
              </div>
            </div>
            <p className="title">
              <Trans
                i18nKey={`customer_reviews_lp_sites_${type}`}
                values={{
                  siteName,
                }}
              />
            </p>
            {withLink ? <Button withArrow label={t('book')} primary small /> : <></>}
          </div>
        </div>
      </>
    );

    return withLink ? (
      <a className={`siteCard ${customClass}`} href={buildUrl(url)}>
        {content}
      </a>
    ) : (
      <div className={`siteCard ${customClass}`}>
        {content}
      </div>
    );
  }
}

export default withTranslation()(SiteCard);

import React from 'react';
import Classnames from 'classnames';

const Arrow = () => (
  <svg className="arrow">
    <use xlinkHref="#icon__arrow" />
  </svg>
);

export const NextStepButton = ({ label, disabled, onClick, onDisabledClick }) => {
  const cls = Classnames('button with-arrow alt', { disabled });
  const _onClick = (e) => {
    if (disabled) {
      e.preventDefault();
      if (typeof onDisabledClick === 'function') {
        onDisabledClick(e);
      }
      return false;
    }
    if (typeof onClick === 'function') {
      onClick(e);
    }
    return true;
  };

  return (
    <div className="cart-end">
      <button
        id="StepButton"
        type="submit"
        className={cls}
        onClick={_onClick}
        aria-disabled={disabled}
      >
        {label}
        <Arrow />
      </button>
    </div>
  );
};

/* @flow */
import React, { useEffect, useState } from 'react';
import { fetchJson } from 'utils/fetch';
import Review from './Review';

const ReviewsList = ({ items, total, limitPerPage }) => {
  const [reviews, setReviews] = useState(items);
  const [currentPage, setCurrentPage] = useState(1);

  const offset = (currentPage - 1) * limitPerPage;

  useEffect(() => {
    if (currentPage > 1) {
      fetchJson(`/reviews/list.json?offset=${offset}&limit=${limitPerPage}`).then((reviews) => {
        setReviews(reviews);
      });
    } else {
      setReviews(items);
    }
  }, [currentPage, items, limitPerPage, offset]);

  function changeCurrentPage(diff) {
    if (currentPage + diff) {
      setCurrentPage(currentPage + diff);
    }
  }

  const isFirstPage = currentPage === 1;
  const hasNextPage = currentPage * limitPerPage < total;
  const hasAtLeastTwoMorePages = currentPage + 2 * limitPerPage < total;

  return (
    <>
      <ul className="reviewsList">
        {reviews?.map((review) => (
          <li key={review.id}>
            <Review review={review} />
          </li>
        ))}
      </ul>
      <ul className="pagination">
        {!isFirstPage && (
          <>
            <li className="prev">
              <button
                className="Button"
                type="button"
                aria-label="previous page"
                onClick={() => changeCurrentPage(-1)}
              />
            </li>
            <li>
              <button
                className="Button"
                type="button"
                aria-label={`page ${currentPage - 1}`}
                onClick={() => changeCurrentPage(-1)}
              >
                {currentPage - 1}
              </button>
            </li>
          </>
        )}
        <li className="active">
          <span>{currentPage}</span>
        </li>
        {hasNextPage && (
          <>
            <li>
              <button
                className="Button"
                type="button"
                aria-label={`page ${currentPage - 1}`}
                onClick={() => changeCurrentPage(+1)}
              >
                {currentPage + 1}
              </button>
            </li>

            {isFirstPage && hasAtLeastTwoMorePages && (
              <li>
                <button
                  className="Button"
                  type="button"
                  aria-label={`page ${currentPage - 2}`}
                  onClick={() => changeCurrentPage(+2)}
                >
                  {currentPage + 2}
                </button>
              </li>
            )}
            <li className="next">
              <button
                className="Button"
                type="button"
                aria-label="next page"
                onClick={() => changeCurrentPage(+1)}
              />
            </li>
          </>
        )}
      </ul>
    </>
  );
};

export default ReviewsList;

/* @flow */

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as BookingActions from '../actions/booking';
import * as TripActions from '../actions/trip';
import type { Site, SiteSwitch, Slots } from '../types';
import type { FormContent } from './Form';

type Props = {
  sites: Array<Site>,
  switches: Array<SiteSwitch>,
  departure: FormContent,
  departureSlots?: Slots,
  arrival: FormContent,
  arrivalSlots?: Slots,
  isFetchingDepartureSlots: boolean,
  isFetchingArrivalSlots: boolean,
  changeDeparture: (departure: FormContent, forceSameSite: boolean) => void,
  changeArrival: (arrival: FormContent) => void,
  fetchTripInfo: () => void,
  selectTripInfo: () => void,
  forceTripInfo: () => void,
  setUnknownTripInfo: () => void,
  clearTripInfos: () => void,
};

const mapStateToProps = (state) => ({
  ...state.booking,
  trip: {
    ...state.trip,
  },
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...BookingActions,
  ...TripActions,
}, dispatch);

export const withOrder = (WrappedComponent: ReactClass<*>) => {
  class WithOrder extends PureComponent {
    static props: Props;

    static displayName = 'HOC(WithOrder)';

    render() {
      const {
        departure,
        departureSlots,
        changeDeparture,
        arrival,
        arrivalSlots,
        changeArrival,
        isFetchingDepartureSlots,
        isFetchingArrivalSlots,
        trip,
        fetchTripInfos,
        selectTripInfos,
        forceTripInfos,
        toggleUnknownTripInfos,
        clearTripInfos,
      } = this.props;

      const tripActions = {
        fetchTripInfos,
        selectTripInfos,
        forceTripInfos,
        toggleUnknownTripInfos,
        clearTripInfos,
      };

      return (
        <WrappedComponent
          {...this.props}
          departure={departure}
          departureSlots={departureSlots}
          onDepartureChanged={changeDeparture}
          isFetchingDepartureSlots={isFetchingDepartureSlots}
          arrival={arrival}
          arrivalSlots={arrivalSlots}
          onArrivalChanged={changeArrival}
          isFetchingArrivalSlots={isFetchingArrivalSlots}
          trip={trip}
          tripActions={tripActions}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithOrder);
};

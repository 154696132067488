import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UpdateOrderContext, updateOrderActions } from '../contexts/UpdateOrderContext';
import API from '../../common/api';
import DayPicker from './DayPicker';
import HourPicker from './HourPicker';
import {
  dateAndHourToMoment,
  fromMomentToString,
  fromStringToDate,
  fromStringToMoment,
} from '../../../utils/dates';
import TripNumber, { ARRIVAL_PREFIX } from './TripNumber';
import { SiteType } from '../../booking/types';

export default function UpdateOrderForm() {
  const { state, dispatch } = useContext(UpdateOrderContext);
  const [isDepartureDone, setIsDepartureDone] = useState(false);
  const [departureSlots, setDepartureSlots] = useState();
  const [isFetchingDeparture, setIsFetchingDeparture] = useState(false);
  const [arrivalSlots, setArrivalSlots] = useState();
  const [isFetchingArrival, setIsFetchingArrival] = useState(false);
  const { t } = useTranslation();

  const valueChanged = (action, value) => {
    dispatch({ type: action, payload: value });
  };

  const getDepartureMaxHour = () => {
    const arrivalUpperBound = moment(state.departure.date).isSame(state.arrival.date, 'day') ?
      dateAndHourToMoment(state.arrival.date, state.arrival.hour) : null;
    if (arrivalUpperBound) {
      return arrivalUpperBound.format('HH:mm');
    }
    return null;
  };

  const getArrivalMinHour = () => (
    moment(state.arrival.date).isSame(state.departure.date, 'day') ? state.departure.hour : null
  );

  const getDepartureSlots = useCallback(async() => {
    setIsFetchingDeparture(true);
    const slots = await API.getSiteDepartureSlots(
      state.departure.site,
      fromStringToDate(state.departure.date),
      state.orderId,
    );
    setDepartureSlots(slots);
    setIsFetchingDeparture(false);
  }, [state.orderId, state.departure.site, state.departure.date]);

  const getArrivalSlots = useCallback(async() => {
    setIsFetchingArrival(true);
    const slots = await API.getSiteArrivalSlots(
      state.arrival.site,
      fromStringToDate(state.arrival.date),
      state.orderId,
    );
    setArrivalSlots(slots);
    setIsFetchingArrival(false);
  }, [state.orderId, state.arrival.site, state.arrival.date]);

  useEffect(() => {
    getDepartureSlots();
  }, [getDepartureSlots]);

  useEffect(() => {
    getArrivalSlots();
  }, [getArrivalSlots]);

  useEffect(() => {
    if (state.initial.departure.date && state.initial.departure.hour) {
      const departure = dateAndHourToMoment(
        state.initial.departure.date,
        state.initial.departure.hour,
      );
      setIsDepartureDone(departure.isBefore());
    }
  }, [state.initial.departure.date, state.initial.departure.hour]);

  const getHourArrivalLabel = (siteKind) => {
    switch (siteKind) {
      case SiteType.Airport: return 'booking-hour_end_flight_label';
      case SiteType.Station: return 'booking-hour_end_train_label';
      default: return 'booking-hour_end_label';
    }
  };

  return (
    <>
      <div>
        <div className="field">
          <DayPicker
            prefix="start"
            notice="booking-departure_notice"
            date={state.departure.date}
            maxDate={state.arrival.date}
            label={`${t('booking-departure_date')} *`}
            onDateChange={(date) => valueChanged(updateOrderActions.updateDepartureDate, date)}
            readOnly={isDepartureDone}
          />
        </div>
        <div className="field">
          <HourPicker
            prefix="start"
            date={state.departure.date}
            hour={state.departure.hour}
            maxHour={getDepartureMaxHour()}
            label="booking-hour_start_label"
            slots={departureSlots}
            tripDetails={state.departure.tripDetails}
            notice="booking-departure_notice"
            onHourChange={(hour) => valueChanged(updateOrderActions.updateDepartureHour, hour)}
            isFetchingSlots={isFetchingDeparture}
            siteType={state.departure.siteKind}
            readOnly={isDepartureDone}
          />
        </div>
      </div>
      <div>
        <div className="field">
          <DayPicker
            prefix="end"
            notice="booking-arrival_notice"
            date={state.arrival.date}
            minDate={
              fromMomentToString(moment.max(fromStringToMoment(state.departure.date), moment()))
            }
            label={`${t('booking-arrival_date')} *`}
            onDateChange={(date) => valueChanged(updateOrderActions.updateArrivalDate, date)}
          />
        </div>
        <div />
        <div className="field compact">
          <TripNumber
            prefix={ARRIVAL_PREFIX}
            site={state.arrival.site}
            siteType={state.arrival.siteKind}
            date={state.arrival.date}
            tripNumber={state.arrival.tripNumber}
            tripDetails={state.arrival.tripDetails}
            onTripNumberChange={
              (value) => valueChanged(updateOrderActions.updateArrivalTripNumber, value)
            }
            onTripDetailsChange={
              (value) => valueChanged(updateOrderActions.updateArrivalTripDetails, value)
            }
          />
        </div>
        <div className="field compact">
          <HourPicker
            prefix="end"
            date={state.arrival.date}
            hour={state.arrival.hour}
            minHour={getArrivalMinHour()}
            label={getHourArrivalLabel(state.arrival.siteKind)}
            slots={arrivalSlots}
            tripDetails={state.arrival.tripDetails}
            notice="booking-arrival_notice"
            onHourChange={(hour) => valueChanged(updateOrderActions.updateArrivalHour, hour)}
            isFetchingSlots={isFetchingArrival}
            siteType={state.arrival.siteKind}
            required
          />
        </div>
      </div>
    </>
  );
}

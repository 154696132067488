/* @flow */

import type { Slots } from '../types';
import type { FormContent } from '../components/Form';
import type { State } from './index';

import { DEPARTURE_CHANGED, ARRIVAL_CHANGED } from '../actions/booking';

import {
  CLEAR_DEPARTURE_SLOTS,
  FETCH_DEPARTURE_SLOTS,
  FETCH_DEPARTURE_SLOTS_SUCCESS,
  CLEAR_ARRIVAL_SLOTS,
  FETCH_ARRIVAL_SLOTS,
  FETCH_ARRIVAL_SLOTS_SUCCESS,
  ARRIVAL_SLOT_AVAILABLE,
  ARRIVAL_SLOT_UNAVAILABLE,
} from '../actions/slot';

export type BookingState = {
  departure: FormContent,
  isFetchingDepartureSlots: boolean,
  departureSlots?: Slots,
  arrival: FormContent,
  isFetchingArrivalSlots: boolean,
  arrivalSlots?: Slots,
  isArrivalSlotAvailable: boolean,
};

const initialState = {
  departure: {},
  arrival: {},
  isArrivalSlotAvailable: false,
  isFetchingDepartureSlots: false,
  isFetchingArrivalSlots: false,
};

export default (state: BookingState = initialState, action: any) => {
  switch (action.type) {
    case DEPARTURE_CHANGED:
      return {
        ...state,
        departure: action.departure,
      };
    case FETCH_DEPARTURE_SLOTS:
      return {
        ...state,
        isFetchingDepartureSlots: true,
      };
    case CLEAR_DEPARTURE_SLOTS:
      return {
        ...state,
        isFetchingDepartureSlots: false,
        departureSlots: undefined,
      };
    case FETCH_DEPARTURE_SLOTS_SUCCESS:
      return {
        ...state,
        departureSlots: action.slots,
        isFetchingDepartureSlots: false,
      };
    case ARRIVAL_CHANGED:
      return {
        ...state,
        arrival: action.arrival,
      };
    case FETCH_ARRIVAL_SLOTS:
      return {
        ...state,
        isFetchingArrivalSlots: true,
      };
    case CLEAR_ARRIVAL_SLOTS:
      return {
        ...state,
        isFetchingArrivalSlots: false,
        arrivalSlots: undefined,
      };
    case FETCH_ARRIVAL_SLOTS_SUCCESS:
      return {
        ...state,
        arrivalSlots: action.slots,
        isFetchingArrivalSlots: false,
      };
    case ARRIVAL_SLOT_AVAILABLE:
      return {
        ...state,
        isArrivalSlotAvailable: true,
      };
    case ARRIVAL_SLOT_UNAVAILABLE:
      return {
        ...state,
        isArrivalSlotAvailable: false,
      };
    default:
      return state;
  }
};

export const getBooking = (state: State) => state.booking;

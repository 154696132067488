import React from 'react';
import { withTranslation } from 'react-i18next';
import RatingStars from '../../reviews/components/RatingStars';
import { getExternalReviewsData } from '../../../utils/ratingReviews';

const InlineReview = ({
  t,
  provider,
}) => {
  const externalReviewsData = getExternalReviewsData();
  const review = externalReviewsData.find((r) => r.name === provider);
  return (
    review ?
      (
        <a target="blank" href={review.link}>
          <div className="reviewInline">
            <div className="rateAmount">
              {review.rate}
            </div>
            <div className="rateStars">
              <RatingStars rating={review.rate} />
            </div>
            <div className="totalReviews">
              +{review.amount} {t('reviews')} <span>{review.name}</span>
            </div>
          </div>
        </a>
      ) :
      null
  );
};

export default withTranslation()(InlineReview);

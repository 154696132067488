/* @flow */

import { getMode } from '../reducers/config';
import type { ConfigMode } from '../types';
import type { State } from '../reducers';

// Only execute thunk if configured mode matches specified one
export const executeIfInMode = (mode: ConfigMode, args?: any) => (
  thunk: any,
) => (dispatch, getState: () => State) => {
  if (getMode(getState()) === mode) {
    thunk(args)(dispatch, getState);
  }
};

// Styles
import 'normalize.css/normalize.css';
import './styles/index.scss';

// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';

import header from './modules/header';
import footer from './modules/footer';
import newsletter from './modules/newsletter';
import cookies from './modules/cookiesConsent';
import bindPlugins from './plugins';
import hookRouter from './router';

bindPlugins();
hookRouter();
header();
footer();
newsletter();
cookies();

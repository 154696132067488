/* @flow */

import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import isEmpty from 'lodash.isempty';
import moment from 'moment';

import { autoSetArrivalCheckpoint, autoSetCheckpointForSite } from '../actions/booking';
import { persistCart } from '../actions/cart';
import { getSummary } from '../reducers/cart';
import { withOrder } from './withOrder';
import { withSites } from './withSites';
import MeetingPoint from './MeetingPoint';
import Trip from './Trip';

import type { Site } from '../types';
import type { FormContent, TripActions } from './Form';

type Props = {
  t: (key: string) => string,
  sites: Array<Site>,
  departure: FormContent,
  arrival: FormContent,
  forceValidation: boolean,
  onDepartureChanged: (departure: FormContent) => void,
  onArrivalChanged: (arrival: FormContent) => void,
  ...TripActions
};

function TravelInfo({
  t,
  sites,
  departure,
  arrival,
  onDepartureChanged,
  onArrivalChanged,
  arrivalSlots,
  trip,
  fetchTripInfos,
  selectTripInfos,
  forceTripInfos,
  toggleUnknownTripInfos,
  clearTripInfos,
  forceValidation,
  persistCart,
}: Props) {
  const isTripValid = (travel) => {
    const unknownTripNumber = t('booking-trip_unknown_number_sel');
    return (
      !!travel.tripNumber && travel.tripNumber !== unknownTripNumber && !isEmpty(travel.tripDetail)
    );
  };
  const isTripForced = (travel) => {
    const unknownTripNumber = t('booking-trip_unknown_number_sel');
    return (
      !!travel.tripNumber && travel.tripNumber !== unknownTripNumber && isEmpty(travel.tripDetail)
    );
  };
  const isTripUnknown = (travel) => {
    const unknownTripNumber = t('booking-trip_unknown_number_sel');
    return (
      !!travel.tripNumber && travel.tripNumber === unknownTripNumber && isEmpty(travel.tripDetail)
    );
  };
  const onChanged = (onChangedFn) => (info) => {
    onChangedFn(info);
    persistCart();
  };

  useEffect(() => {
    if (!arrival.tripDetail) {
      fetchTripInfos(
        'end',
        arrival.site,
        moment(arrival.date).format('DD/MM/YYYY'),
        arrival.tripNumber,
      );
    }

    if (isTripValid(arrival)) {
      selectTripInfos('end');
    }

    if (isTripForced(arrival)) {
      forceTripInfos('end');
    }

    if (isTripUnknown(arrival)) {
      toggleUnknownTripInfos('end');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departure?.site && sites && sites.length) {
      onChanged(onDepartureChanged(autoSetCheckpointForSite(sites)(departure)));
      onChanged(onArrivalChanged(autoSetArrivalCheckpoint(departure)(arrival)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, departure.site, arrival.site]);

  return (
    <>
      <MeetingPoint
        label={t('checkpoint-departure')}
        sites={sites}
        content={departure}
        onChanged={onChanged(onDepartureChanged)}
        forceValidation={forceValidation}
      />
      <MeetingPoint
        label={t('checkpoint-arrival')}
        sites={sites}
        content={arrival}
        onChanged={onChanged(onArrivalChanged)}
        forceValidation={forceValidation}
      />

      <div className="MeetingPointNotice">
        <svg>
          <use xlinkHref="#icon__info" />
        </svg>
        <div>
          {t('booking-meeting-point-notice')}
        </div>
      </div>

      <div className="Separator" />

      <Trip
        prefix="end"
        sites={sites}
        content={arrival}
        onChange={onChanged(onArrivalChanged)}
        trip={trip.end}
        slots={arrivalSlots}
        tripActions={{
          fetchTripInfos,
          selectTripInfos,
          forceTripInfos,
          toggleUnknownTripInfos,
          clearTripInfos,
        }}
        forceValidation={forceValidation}
      />

      <div className="ReturnTripNotice">
        <svg>
          <use xlinkHref="#icon__info" />
        </svg>
        <div>
          {t('booking-return-trip-notice')}
        </div>
      </div>
    </>
  );
}


const mapStateToProps = (state) => ({
  summary: getSummary(state),
});

export default compose(
  withOrder,
  withSites,
  connect(mapStateToProps, { persistCart }),
  withTranslation(),
)(TravelInfo);

/* @flow */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Button } from '@bluevalet/common-ui';
import { withSites } from './withSites';
import { withOrder } from './withOrder';
import Departure from './DepartureForm';
import Arrival from './ArrivalForm';
import EstimationSummary from './EstimationSummary';
import InlineRating from '../../common/components/InlineRating';
import { estimateBooking } from '../actions/estimate';
import {
  getIsFetching,
  getEstimation,
  getDisplayFormValidationErrors,
  getEstimationError,
} from '../reducers/estimate';
import type { Site, Estimation, Slots } from '../types';
import type { FormContent } from './Form';

type Props = {
  t: (key: string) => string,
  sites: Array<Site>,
  arrivalSites: Array<Site>,
  departure: FormContent,
  departureSlots?: Slots,
  isFetchingDepartureSlots: boolean,
  arrival: FormContent,
  arrivalSlots?: Slots,
  isFetchingArrivalSlots: boolean,
  isFetchingEstimation: boolean,
  estimation?: Estimation,
  forceValidation: boolean,
  onDepartureChanged: (departure: FormContent, forceSameSite: boolean) => void,
  onArrivalChanged: (arrival: FormContent) => void,
  onEstimatePrice: () => void,
  error?: string,
};

const FastBooking = ({
  t,
  sites,
  arrivalSites,
  departure,
  departureSlots,
  isFetchingDepartureSlots,
  arrival,
  arrivalSlots,
  isFetchingArrivalSlots,
  isFetchingEstimation,
  estimation,
  forceValidation,
  onDepartureChanged,
  onArrivalChanged,
  onEstimatePrice,
  error,
}: Props) => {
  const [displayArrivalSite, setDisplayArrivalSite] = useState(false);

  useEffect(() => {
    if (departure?.site && arrivalSites.length === 1) {
      setDisplayArrivalSite(false);
    }
  }, [departure?.site, arrivalSites]);

  return (
    <React.Fragment>
      <form id="bookingHome">
        <div className="search">
          <div className="title">{t('booking-departure')}</div>
          <Departure
            fastBooking
            displayNotice
            content={departure}
            sites={sites}
            slots={departureSlots}
            forceValidation={forceValidation}
            onChange={(departure) => onDepartureChanged(departure, true)}
            isFetchingSlots={isFetchingDepartureSlots}
            displayArrivalSiteButton={arrivalSites.length > 1 && !displayArrivalSite}
            onDifferentArrivalSiteEnable={() => setDisplayArrivalSite(true)}
          />
        </div>
        <div className="BookingHomeSeparator" />
        <div className="search">
          <div className="title">{t('booking-arrival')}</div>
          <Arrival
            fastBooking
            displayNotice
            displaySite={displayArrivalSite}
            content={arrival}
            sites={arrivalSites}
            slots={arrivalSlots}
            forceValidation={forceValidation}
            onChange={onArrivalChanged}
            isFetchingSlots={isFetchingArrivalSlots}
          />
        </div>
        <div className="reviewsFastBooking">
          <InlineRating provider="Google" />
        </div>
        {error && (
          <>
            <div className="BookingHomeSeparator" />
            <div className="estimateError">{error}</div>
          </>
        )}
        <div className="otps-form">
          <Button
            data-cy="submit-fast-booking"
            primary
            label={t('booking-get_price')}
            onClick={() => onEstimatePrice()}
            loading={isFetchingEstimation}
            disabled={!!estimation?.total}
          />
        </div>
      </form>
      <EstimationSummary
        sites={sites}
        estimation={estimation}
        departure={departure}
        arrival={arrival}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isFetchingEstimation: getIsFetching(state),
  estimation: getEstimation(state),
  forceValidation: getDisplayFormValidationErrors(state),
  error: getEstimationError(state),
});

export default compose(
  withOrder,
  withSites,
  connect(mapStateToProps, {
    onEstimatePrice: estimateBooking,
  }),
  withTranslation(),
)(FastBooking);

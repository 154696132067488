/* @flow */

import moment from 'moment';
import { pushEvent } from '../tagging';
import { getLang } from '../../i18n';
import { TOGGLE_OPTION, UPDATE_CART_SUCCESS, FETCH_SUMMARY_SUCCESS } from './actions/cart';
import { getServiceById } from './reducers/services';
import { getBooking } from './reducers/booking';
import { getSites } from './reducers/sites';
import { getSummary } from './reducers/cart';
import type { Site, Estimation, OrderSummary } from './types';
import type { FormContent } from './components/Form';

type Booking = {
  departure: FormContent,
  arrival: FormContent,
};

function getSiteLabel(sites, id) {
  const site = sites.find((s) => s.id.toString() === id);
  return site ? site.name : '';
}

function createBeyablePayload(sites: Array<Site>, booking: Booking) {
  const getDate = (content: FormContent) => (content.date ? moment(content.date).format('DDMMYYYY') : '');

  const getHour = (content: FormContent) => (content.hour ? moment(content.hour, 'HH:mm').format('HHmm') : '');

  return {
    start_place_label: getSiteLabel(sites, booking.departure.site),
    start_date: getDate(booking.departure),
    start_timeslot: getHour(booking.departure),
    end_place_label: getSiteLabel(sites, booking.arrival.site),
    end_date: getDate(booking.arrival),
    end_timeslot: getHour(booking.arrival),
  };
}

export function pushServiceEvent(service) {
  // GA4
  pushEvent('add_service_to_cart', {
    currency: 'EUR',
    value: service.price,
    name: service.label,
  });
}

export function pushCancellationOptionEvent(service) {
  // GA4
  pushEvent('add_cancel_option', {
    name: service.label,
  });
}

function pushBookingOptionEvents(store, action) {
  const getService = (id) => {
    const state = store.getState();
    return getServiceById(state, id);
  };

  const { option, previous } = action;
  const service = getService(option);
  if (service && !previous) {
    pushEvent('addToCart', {
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [
            {
              name: service.label,
              price: service.price,
              quantity: 1,
            },
          ],
        },
      },
    });

    if (service.isInsurance) {
      pushCancellationOptionEvent(service);
    } else {
      pushServiceEvent(service);
    }
  }
}

function pushBookingEvents(store) {
  const state = store.getState();
  const sites = getSites(state);
  const booking = getBooking(state);
  const summary = getSummary(state);

  if (summary) {
    const payload = createBeyablePayload(sites, booking);
    pushEvent('bookingForm', {
      ...payload,
      nb_days_reservation: summary.durationInDays,
    });
  }
}

export function handleBookingAction(store, action) {
  switch (action.type) {
    case TOGGLE_OPTION:
      pushBookingOptionEvents(store, action);
      break;
    case UPDATE_CART_SUCCESS:
    case FETCH_SUMMARY_SUCCESS:
      pushBookingEvents(store, action);
      break;
    default:
      break;
  }
}

export function pushEstimationEvents(sites: Array<Site>, estimation: Estimation, booking: Booking) {
  const payload = createBeyablePayload(sites, booking);

  pushEvent('bookingForm', {
    ...payload,
    nb_days_reservation: estimation.durationInDays,
  });

  pushEvent('updatevirtualpath', {
    virtualDocumentPath: `/${getLang()}/popin`,
    virtualDocumentTitle: 'Pop-In Réservation',
  });

  // GA4
  pushEvent('simulate_price', {
    currency: 'EUR',
    value: estimation.total,
    departure_location: getSiteLabel(sites, booking.departure.site),
    duration: estimation.durationInDays,
  });
}

export function pushEstimationErrorEvents(sites: Array<Site>, booking: Booking) {
  pushEvent('simulate_price_error', {
    departure_location: getSiteLabel(sites, booking.departure.site),
  });
}

export function pushValidateEstimationEvents(estimation: Estimation) {
  // GA4
  pushEvent('start_booking', {
    currency: 'EUR',
    value: estimation.total,
  });
}

export function pushCheckoutEvents(summary: OrderSummary) {
  // GA4
  pushEvent('start_checkout', {
    currency: 'EUR',
    value: summary.total,
    coupon: summary.voucher?.status === 'Valid' ? summary.voucher?.code : null,
  });
}

export function pushPurchaseEvent(total, orderNumber) {
  // GA4
  pushEvent('booking_success', {
    currency: 'EUR',
    transaction_id: orderNumber,
    value: total,
  });
}

export function pushPurchaseFailureEvent() {
  // GA4
  pushEvent('purchase_error', {});
}

export function pushSignUpCartEvent() {
  // GA4
  pushEvent('sign_up_cart', {});
}

export function pushValidateSignUpCartEvent() {
  // GA4
  pushEvent('sign_up_cart_process_end', {});
}

export function pushSignInCartEvent() {
  // GA4
  pushEvent('login_cart', {});
}

export function pushNewsletterEvent(from: string) {
  // GA4
  pushEvent('newsletter_subscription', {
    lang: getLang(),
    from,
  });
}

export function pushLoginHeaderEvent() {
  // GA4
  pushEvent('login_header', {});
}

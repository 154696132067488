/* @flow */

import API from '../../common/api';

export const FETCH_INFORMATIONS: string = 'FETCH_INFORMATIONS';
export const FETCH_INFORMATIONS_SUCCESS: string = 'FETCH_INFORMATIONS_SUCCESS';
export const FETCH_INFORMATIONS_ABORT: string = 'FETCH_INFORMATIONS_ABORT';
export const SELECT_INFORMATIONS: string = 'SELECT_INFORMATIONS';
export const FORCE_INFORMATIONS: string = 'FORCE_INFORMATIONS';
export const TOGGLE_UNKNOWN_INFORMATIONS: string = 'TOGGLE_UNKNOWN_INFORMATIONS';
export const CLEAR_INFORMATIONS: string = 'CLEAR_INFORMATIONS';

const fetchSuccess = (prefix: string, results: ?(Object[])) => ({
  type: FETCH_INFORMATIONS_SUCCESS,
  prefix,
  results,
});

export const fetchTripInfos = (
  prefix: string,
  site: string,
  date: string,
  tripNumber: string,
  signal: any,
) => (dispatch) => {
  dispatch({
    type: FETCH_INFORMATIONS,
    prefix,
    tripNumber,
  });

  const uriPrefix = prefix === 'start' ? 'departure' : 'arrival';

  if (tripNumber && date && site) {
    API.getTripInfos(uriPrefix, site, date, tripNumber, signal)
      .then((results) => {
        dispatch(fetchSuccess(prefix, [results]));
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          dispatch(fetchSuccess(prefix, []));
        }
      });
  }
};

export const selectTripInfos = (prefix: string, details: Object) => ({
  type: SELECT_INFORMATIONS,
  prefix,
  details,
});

export const forceTripInfos = (prefix: string) => ({
  type: FORCE_INFORMATIONS,
  prefix,
});

export const toggleUnknownTripInfos = (prefix: string) => ({
  type: TOGGLE_UNKNOWN_INFORMATIONS,
  prefix,
});

export const clearTripInfos = (prefix: string) => ({
  type: CLEAR_INFORMATIONS,
  prefix,
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import { COOKIE_CONSENT_OPEN_EVENT } from './service';

export default function CookieConsentLink() {
  const { t } = useTranslation();

  const handeOpenModal = () => {
    window.dispatchEvent(new Event(COOKIE_CONSENT_OPEN_EVENT));
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={handeOpenModal}>{t('manage_cookies')}</a>
  );
}

import cookies from './cookies';
import datePicker from './date-picker';
import html from './html';
import form from './form';
import responsive from './responsive';
import smoothScroll from './smooth-scroll';
import sponsorship from './sponsorship';
import smartBanner from './smartBanner';
import selects from './select';
import phoneNumber from './phone-number';

export default () => {
  [
    cookies,
    datePicker,
    html,
    form,
    responsive,
    smoothScroll,
    sponsorship,
    smartBanner,
    selects,
    phoneNumber,
  ].map(plugin => plugin.call(this));
};

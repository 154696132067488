import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import taggingMiddleware from './middlewares/tagging';
import createStore from './createPersistedStore';
import FastBooking from './components/FastBooking';

export default function() {
  const root = document.getElementById('FastBookingRoot');

  if (root) {
    const site = root.getAttribute('data-site') || '';

    const initialState = {
      booking: {
        departure: {
          site,
        },
        arrival: {
          site,
        },
      },
    };

    const persistedStore = createStore('estimate', initialState, [taggingMiddleware]);

    // When display FastBooking, flush the current local storage if it's allowed
    const parsedQuery = new URLSearchParams(window.location.search);
    if (!parsedQuery.has('keep') || parsedQuery.get('keep') !== 'true') {
      persistedStore.persistor.purge();
    }

    ReactDOM.render(
      <React.Suspense fallback={<div className="fallback" />}>
        <Provider store={persistedStore.store}>
          <I18nextProvider i18n={i18n}>
            <FastBooking
              persistor={persistedStore.persistor}
            />
          </I18nextProvider>
        </Provider>
      </React.Suspense>,
      root,
    );
  }
}

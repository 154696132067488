import React from 'react';
import RatingStars from './RatingStars';

export default function Review({ review }) {
  return (
    <div className="review">
      <div className="header">
        <div className="authorWithRating">
          <div className="review-rating">
            <RatingStars rating={review.score} />
          </div>
          <p className="author">{review.customerAnonymous || review.author}</p>
        </div>
        <p className="date">{review.date}</p>
      </div>
      <p className="content">« {review.content} »</p>
    </div>
  );
}

import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import { UpdateOrderProvider } from './contexts/UpdateOrderContext';
import UpdateOrderForm from './components/UpdateOrderForm';
import { simulateUpdate } from './orderSimulation';

export default function() {
  // Handling order update
  const $updateModal = $('#ConfirmUpdateModal');
  const submitForm = () => {
    $('#orderUpdateForm').trigger('submit');
  };

  if ($updateModal && $updateModal.length) {
    $updateModal.find('.js-close-popup')
      .on('click', () => $.magnificPopup.close());

    $updateModal.find('.js-submit-popup').on('click', () => {
      submitForm();
    });

    // Intercept submit
    const $submit = $('#SimulateUpdateBtn');
    $submit.on('click', async(event) => {
      event.preventDefault();
      $submit.addClass('loading');
      $submit.prop('disabled', true);
      await simulateUpdate($updateModal, submitForm);
      $submit.removeClass('loading');
      $submit.prop('disabled', false);
      return false;
    });
  }

  // Handling order cancellation
  const $cancelModal = $('#CancelModal');
  if ($cancelModal && $cancelModal.length) {
    // Handling buttons behavior
    $cancelModal.find('.js-close-popup').on('click', () => $.magnificPopup.close());
    $cancelModal.find('form').on('submit', (event) => {
      const $button = $(event.currentTarget).find('button[type="submit"]');
      $button.prop('disabled', true);
      return true;
    });

    // Intercept submit
    $('#CancelOrder').on('click', (event) => {
      event.preventDefault();

      $.magnificPopup.open({
        items: {
          src: $cancelModal,
        },
      });

      return false;
    });
  }

  // Update form (date+hour+tripNumber fields on both departure & arrival)
  const tripRoot = document.getElementById('TripRoot');

  const departureTrip = tripRoot.getAttribute('data-departure-trip-details');
  const arrivalTrip = tripRoot.getAttribute('data-arrival-trip-details');

  const initialState = {
    orderId: tripRoot.getAttribute('data-order-id'),
    departure: {
      site: tripRoot.getAttribute('data-departure-site'),
      siteKind: tripRoot.getAttribute('data-departure-site-kind'),
      date: tripRoot.getAttribute('data-departure-date'),
      hour: tripRoot.getAttribute('data-departure-hour'),
      tripNumber: tripRoot.getAttribute('data-departure-trip-number'),
      tripDetails: departureTrip ? JSON.parse(departureTrip) : null,
    },
    arrival: {
      site: tripRoot.getAttribute('data-arrival-site'),
      siteKind: tripRoot.getAttribute('data-arrival-site-kind'),
      date: tripRoot.getAttribute('data-arrival-date'),
      hour: tripRoot.getAttribute('data-arrival-hour'),
      tripNumber: tripRoot.getAttribute('data-arrival-trip-number'),
      tripDetails: arrivalTrip ? JSON.parse(arrivalTrip) : null,
    },
  };
  if (tripRoot) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <UpdateOrderProvider initState={initialState}>
            <UpdateOrderForm />
          </UpdateOrderProvider>
        </I18nextProvider>
      </React.Suspense>,
      tripRoot,
    );
  }
}

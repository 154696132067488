import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import SiteCard from '../common/SiteCard';

export default function() {
  const siteRoots = document.getElementsByClassName('siteCardRoot');
  if (siteRoots && siteRoots.length) {
    [...siteRoots].forEach((root) => {
      const key = root.getAttribute('data-site');
      ReactDOM.render(
        <React.Suspense fallback={<div />}>
          <I18nextProvider i18n={i18n}>
            <SiteCard siteKey={key} withLink={false} />
          </I18nextProvider>
        </React.Suspense>,
        root,
      );
    });
  }
}

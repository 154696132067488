import $ from 'jquery';

export default function () {
  const $element = $('.sliderPhoto');
  const $ul = $('ul', $element);
  const $lis = $('li', $ul);
  let interval = null;

  const slide = (index) => {
    $ul.css({
      left: $element.width() * index * -1,
    });
    $('ol li', $element).removeClass('active');
    $(`ol li:eq(${index})`, $element).addClass('active');
  };

  const indicator = (length) => {
    $('<ol></ol>').appendTo($element);
    const $ol = $('ol', $element);
    for (let i = 0; i < length; i += 1) {
      $(`<li data-index="${i}"></li>`).appendTo($ol);
    }
    $('li', $ol).bind('click', (e) => {
      const $li = $(e.currentTarget);
      const id = parseInt($li.data('index'), 10);
      slide(id);
    });
  };

  const initSlider = () => {
    $ul.width($element.width() * $lis.length);
    $lis.width($element.width());
    $lis.first().addClass('active');
    $('ol', $element).remove();
    indicator($lis.length);
    clearInterval(interval);
    interval = setInterval(() => {
      const current = $('li.active', $ul);
      const next = current.next();
      const index = current.index() >= $lis.length - 1 ? 0 : next.index();
      current.removeClass('active');
      if (index === 0) $lis.first().addClass('active');
      else next.addClass('active');
      slide(index);
    }, 4000);
  };

  $(window).resize(() => {
    initSlider();
    slide(0);
  });

  initSlider();
}

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import Reviews from './components/Reviews';

export default function SiteReviews() {
  const reviewsRoot = document.getElementById('ReviewsRoot');
  if (reviewsRoot) {
    const siteCode = reviewsRoot.getAttribute('data-site-code');
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <Reviews siteCode={siteCode} />
        </I18nextProvider>
      </React.Suspense>,
      reviewsRoot,
    );
  }
}

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonIcon, StepsFlow } from '@bluevalet/common-ui';
import HistoricIcon from '@bluevalet/react-icons/historic';
import UserIcon from '@bluevalet/react-icons/user';
import OutsideWashIcon from '@bluevalet/react-icons/outside-wash';
import ValetIcon from '@bluevalet/react-icons/valet';

import { StepTypes } from '../types';

const isPreviousStep = (current, step) => {
  switch (current) {
    case StepTypes.information:
      switch (step) {
        case StepTypes.booking:
        case StepTypes.services:
        case StepTypes.options:
          return true;
        default: return false;
      }

    case StepTypes.options:
      switch (step) {
        case StepTypes.booking:
        case StepTypes.services:
          return true;
        default: return false;
      }

    case StepTypes.services:
      switch (step) {
        case StepTypes.booking: return true;
        default: return false;
      }

    default: return false;
  }
};

function StepsMenu({ t, currentStep, currentStepError }) {
  const menu = [
    {
      id: 1,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <HistoricIcon size={24} />
          <span>{t('booking-menu_order')}</span>
        </ButtonIcon>
      ),
      href: '/',
      isDisabled: false,
      isSuccess: true,
    },
    {
      id: 2,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <OutsideWashIcon size={24} />
          <span>{t('booking-menu_services')}</span>
        </ButtonIcon>
      ),
      href: '/cart/services',
      isDisabled: !isPreviousStep(currentStep, StepTypes.services)
        && currentStep !== StepTypes.services,
      isActive: currentStep === StepTypes.services,
      isError: currentStep === StepTypes.services && currentStepError,
      isSuccess: isPreviousStep(currentStep, StepTypes.services),
    },
    {
      id: 3,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <ValetIcon size={24} />
          <span>{t('booking-menu_options')}</span>
        </ButtonIcon>
      ),
      href: '/cart/options',
      isDisabled: !isPreviousStep(currentStep, StepTypes.options)
        && currentStep !== StepTypes.options,
      isActive: currentStep === StepTypes.options,
      isError: currentStep === StepTypes.options && currentStepError,
      isSuccess: isPreviousStep(currentStep, StepTypes.options),

    },
    {
      id: 4,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <UserIcon size={24} />
          <span>{t('booking-menu_informations')}</span>
        </ButtonIcon>
      ),
      href: '/cart/info',
      isDisabled: !isPreviousStep(currentStep, StepTypes.information)
        && currentStep !== StepTypes.information,
      isActive: currentStep === StepTypes.information,
      isError: currentStep === StepTypes.information && currentStepError,
      isSuccess: isPreviousStep(currentStep, StepTypes.information),
    },
  ];

  return (
    <div className="StepsMenu">
      <StepsFlow
        steps={menu}
      />
    </div>
  );
}

export default withTranslation()(StepsMenu);

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { isLgUp } from 'utils/navigator';
import ExternalRatings from './components/ExternalRatings';
import ReviewsList from './components/ReviewsList';
import i18n from '../../i18n';

export default function() {
  const externalRatingsRoot = document.getElementById('ExternalRatingsRoot');
  const reviewsListRoot = document.getElementById('ReviewsListRoot');

  const limitPerPage = isLgUp ? 8 : 4;

  if (externalRatingsRoot) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <ExternalRatings />
        </I18nextProvider>
      </React.Suspense>,
      externalRatingsRoot,
    );
  }

  if (reviewsListRoot) {
    const { items, total } = window.__INITIAL_DATA__;
    const filteredItems = items.slice(0, limitPerPage);

    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <ReviewsList items={filteredItems} total={total} limitPerPage={limitPerPage} />
        </I18nextProvider>
      </React.Suspense>,
      reviewsListRoot,
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';

import Form from './Form';

function Arrival(props) {
  return (
    <Form
      prefix="end"
      notice="booking-arrival_notice"
      {...props}
    />
  );
}

const mapStateToProps = ({ trip: { end: trip } }) => ({ trip });

export default connect(mapStateToProps)(Arrival);

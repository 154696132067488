/* @flow */

import { FETCH_DETAILS_SUCCESS, UPDATE_INFORMATION } from '../actions/information';

import type { State } from './index';

export type InformationState = {
  lastName?: string,
  firstName?: string,
  email?: string,
  phoneNumber?: string,
  company?: string,
  address: {
    firstLine?: string,
    secondLine?: string,
    zipCode?: string,
    city?: string,
    country?: string,
  },
  expensesTitle?: string
};

const initialState = {};

export default (state: InformationState = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_INFORMATION:
      return {
        ...state,
        ...action.information,
      };
    case FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.information,
      };
    default:
      return state;
  }
};

export const getInformation = (state: State) => state.information;

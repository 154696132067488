import URI from 'urijs';
import { fetchJson } from '../../utils/fetch';

const POLLING_INTERVAL = 2000;
const POLLING_MAX_ITERATIONS = 120;

export function validatePayment() {
  const intent = URI(window.location).query(true).intent_secret;

  let timer = null;
  let iterations = 0;

  const stopPolling = () => clearInterval(timer);

  const handlePolling = (res) => {
    iterations += 1;

    if (res && (res.status !== 'Pending' || iterations >= POLLING_MAX_ITERATIONS)) {
      stopPolling();
      document.location.replace(res.redirectUrl);
    }
  };

  timer = setInterval(() => {
    fetchJson(`/cart/payment/status?intent_secret=${intent}`, {
      method: 'GET',
    })
      .then((res) => {
        handlePolling(res);
      })
      .catch(() => handlePolling());
  }, POLLING_INTERVAL);
}

import $ from 'jquery';

export default function() {
  $('#RemoveAccount').on('click', (event) => {
    event.preventDefault();

    const $button = $(event.target);

    const content = $button.attr('data-content');
    const url = $button.attr('data-url');

    if (content && url) {
      const result = window.confirm(content);
      if (result) {
        const form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', url);

        document.body.appendChild(form);
        form.submit();
      }
    }
  });
}

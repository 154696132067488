import $ from 'jquery';

export const bindFormValidation = () => {
  $('form .field input.error').on('change keyup', (evt) => {
    const $element = $(evt.target);

    const { value } = evt.target;
    if (value && value.length) {
      $element.removeClass('error');
    }
  });

  // Fix le bug IOS qui n'affiche pas le message de validation lorsque l'écran a du scroll
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS) {
    let currentScrollPosition = $(document).scrollTop();
    $(document).scroll(() => {
      currentScrollPosition = $(this).scrollTop();
    });
    $('.form.login input').focus((e) => {
      e.preventDefault();
      $(document).scrollTop(currentScrollPosition);
    });
  }
};

const bindMultiSubmit = () => {
  $('form').submit(() => {
    // submit more than once return false
    $(this).submit(() => false);
    // submit once return true
    return true;
  });
};

export default () => {
  bindFormValidation();
  bindMultiSubmit();
};

/* @flow */

import { FETCH_COMPANIES, FETCH_COMPANIES_SUCCESS } from '../actions/business';
import type { BusinessCompany } from '../types';
import type { State } from './index';

export type BusinessState = {
  isFetching: boolean,
  companies: Array<BusinessCompany>,
};

const initialState = {
  isFetching: false,
  companies: [],
};

export default (state: BusinessState = initialState, action: any) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        companies: action.companies,
      };
    default:
      return state;
  }
};

export const getCompanies = (state: State) => state.business.companies;

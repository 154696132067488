/* @flow */

import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';

import AvailableOption from './AvailableOption';
import { OptionTypes } from '../types';

import type { Service, OrderSummary } from '../types';

interface Props {
  summary: OrderSummary;
  options: Array<Service>;
  addOption: (service: number, previous: boolean) => void;
  removeOption: (id: number) => void;
}

const AvailableOptions = ({ summary, options, t, addOption, removeOption }: Props) => {
  const [isBasicSelectedLocally, setBasicSelectedLocally] = useState(false);
  const [isCancellationSelectedLocally, setCancellationSelectedLocally] = useState(false);

  useEffect(() => {
    setBasicSelectedLocally(summary.hasBasicOption);
    setCancellationSelectedLocally(summary.hasCancellationOption);
  }, [summary]);

  const addCancellation = () => {
    setBasicSelectedLocally(false);
    setCancellationSelectedLocally(true);
    addOption(summary.cancellationOptionId);
  };

  const addBasic = () => {
    setBasicSelectedLocally(true);
    setCancellationSelectedLocally(false);
    removeOption(summary.cancellationOptionId);
  };

  const removeCancellation = () => {
    if (!summary.isCancellationOptionFree) {
      setBasicSelectedLocally(false);
      setCancellationSelectedLocally(true);
      removeOption(summary.cancellationOptionId);
    }
  };

  const cancelOption = useMemo(() => options.find(({ isInsurance }) => isInsurance), [options]);

  return (
    <div className="optionSelection">
      <div className="services">
        {cancelOption && (
          <AvailableOption
            optionType={OptionTypes.premium}
            price={cancelOption.price === 0 ? t('included') : `${cancelOption?.price}€`}
            hasOption={isCancellationSelectedLocally}
            addOption={addCancellation}
            removeOption={removeCancellation}
            isRecommended
          />
        )}
        {!summary.isCancellationOptionFree && (
          <AvailableOption
            optionType={OptionTypes.basic}
            price={t('free')}
            hasOption={isBasicSelectedLocally}
            addOption={addBasic}
          />
        )}
      </div>
    </div>
  );
};

export default withTranslation()(AvailableOptions);

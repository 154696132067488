import URI from 'urijs';
import ie from './ie';

class FetchError extends Error {
  static url: string;

  static res: Response;

  constructor(url, res) {
    super(`Error fetching url ${url}`);
    this.url = url;
    this.res = res;
    this.name = 'FetchError';
  }
}

const withQueryString = (url) => {
  if (!ie) {
    return url;
  }

  const timestamp = Date.now().toString();
  return URI(url)
    .addQuery(timestamp)
    .toString();
};

const withNoCacheHeaders = (params) => {
  const _params = params;
  if (!ie) {
    return _params;
  }

  if (!_params.headers) {
    _params.headers = {};
  }

  _params.headers['X-Requested-With'] = 'XMLHttpRequest';
  _params.headers.Expires = '-1';
  _params.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private';

  return _params;
};

export default function fetchUrl(
  url: string,
  params: any = {},
): Promise<Response> {
  const allParams = {
    credentials: 'same-origin',
    ...params,
  };

  return fetch(withQueryString(url), withNoCacheHeaders(allParams)).then(
    (res) => {
      if (res.status < 400) {
        return res;
      }
      throw new FetchError(url, res);
    },
  );
}

export const fetchJson = (url: string, params: any = {}): Promise<Object> => (
  fetchUrl(url, {
    ...params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(res => res.json())
);

export const fetchJsonAbort = (url: string, params: any = {}) => {
  const controller = new AbortController();
  const { signal } = controller;

  const fetchParams = {
    ...params,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    signal, // extend provided params with AbortController signal
  };

  const promise = fetch(url, fetchParams).then(data => data.json());

  return [
    promise,
    controller.abort.bind(controller), // notice binding context
  ];
};

export const postJson = (
  url: string,
  data: Object,
  params: any = {},
): Promise<Object> => (
  fetchJson(url, {
    ...params,
    method: 'POST',
    body: JSON.stringify(data),
  })
);

import $ from 'jquery';

export function scrollTo($element, duration = 'slow') {
  let element = $element;
  if (element instanceof HTMLElement) {
    element = $(element);
  }
  if (element.length > 0) {
    $('html, body').animate(
      {
        scrollTop: element.offset().top - $('#Header').height() - 5,
      },
      duration,
    );
  }
}

export function scrollToCurrentHash() {
  const currentHash = window.location.hash;
  if (currentHash && currentHash.length) {
    if (currentHash !== '#ValidateForm') {
      try {
        const $target = $(`${currentHash}`);
        setTimeout(() => scrollTo($target, 0), 100);
      } catch (error) { /* Do nothing */ }
    }
  }
}

export default () => {
  scrollToCurrentHash();

  $('a[href^="#"]:not([href="#"])').click((event) => {
    const el = $(event.currentTarget).attr('href');
    scrollTo($(el));
    return false;
  });
};

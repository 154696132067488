/* @flow */

import API from '../../common/api';
import type { BusinessCompany } from '../types';
import type { State } from '../reducers';

export const FETCH_COMPANIES: string = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS: string = 'FETCH_COMPANIES_SUCCESS';

const fetchCompaniesSuccess = (companies: Array<BusinessCompany>) => ({
  type: FETCH_COMPANIES_SUCCESS,
  companies,
});

export const fetchCompanies = () => (dispatch, getState: () => State) => {
  const { isFetching } = getState().business;
  if (isFetching) {
    return;
  }

  dispatch({
    type: FETCH_COMPANIES,
  });

  API.getCompanies().then((res) => {
    if (res.status === 200) {
      res
        .json()
        .then((companies) => dispatch(fetchCompaniesSuccess(companies.sort((c) => c.id))));
    } else {
      dispatch(fetchCompaniesSuccess([]));
    }
  });
};

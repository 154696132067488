/* @flow */

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import isEmpty from 'lodash.isempty';
import Classnames from 'classnames';
import TimeIcon from '@bluevalet/react-icons/time';
import { Fixed, InputSelect, Modal } from '@bluevalet/common-ui';

import { MinWarningDuration } from '../../../conf/domain';
import {
  getAvailableTimesFromSlots,
  getAvailableSlot,
  isErrorField,
  isObjectEmpty,
} from '../utils';
import { persistCart } from '../actions/cart';
import { setArrivalSlotAvailable, setArrivalSlotUnAvailable } from '../actions/slot';

import type { Slots } from '../types';

const moment = extendMoment(Moment);

type Props = {
  t: (key: string) => string,
  prefix: String,
  date?: Date,
  hour?: string,
  label?: string,
  placeholder?: string,
  slots?: Slots,
  forceValidation?: boolean,
  isFetchingSlots: boolean,
  onHourChange: (value?: string) => void,
  siteType: string,
  departure: any,
  arrival: any,
  isArrivalSlotAvailable: boolean,
  trip: any,
  setArrivalSlotAvailable: () => void,
  setArrivalSlotUnAvailable: () => void,
  persistCart: () => void,
};

class TripHourPicker extends PureComponent {
  static props: Props;

  constructor(props) {
    super(props);
    this.refName = `${props.prefix}Input`;
    this[this.refName] = React.createRef();
    this.unavailableModalRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      date,
      hour,
      trip,
      tripDetail,
      isArrivalSlotAvailable,
      setArrivalSlotAvailable,
      setArrivalSlotUnAvailable,
      persistCart,
    } = this.props;

    if (this.isDeparture() && !isEmpty(tripDetail) && !isArrivalSlotAvailable) {
      if (hour !== undefined && hour.length > 0) {
        const selectedDate = moment(date);
        const [hours, minutes] = hour.split(':');
        selectedDate.set({ hours, minutes });
        const departureTime = moment(tripDetail.departureTime, 'DD/MM/YYYY HH:mm');
        if (selectedDate.isAfter(departureTime)) {
          this.onHourChange('');
        }
      }
    }

    if (this.isArrival()) {
      if (tripDetail && !isObjectEmpty(tripDetail)) {
        const slots = this.getDisplayedSlots(true);
        const findSlot = getAvailableSlot(tripDetail, slots);
        if (tripDetail !== prevProps.tripDetail) {
          if (!isEmpty(findSlot) && !findSlot.isUnavailable) {
            this.onHourChange(findSlot.value);
            setArrivalSlotAvailable();
            persistCart();
          } else {
            setArrivalSlotUnAvailable();
            this.openModal(this.unavailableModalRef);
          }
        } else if (
          (trip.isUnknown || trip.isForced || (trip.isValid && slots.length)) &&
          !isArrivalSlotAvailable
        ) {
          setArrivalSlotAvailable();
        }
      }
    }
  }

  onHourChange = (value) => {
    const { onHourChange } = this.props;
    onHourChange(value);
  };

  getDisplayedSlots(withPrevious: Boolean = false) {
    let displayedSlots = [];
    const { date, departure, arrival, slots, tripDetail } = this.props;

    if (slots) {
      const sameDateForDeparture = moment(arrival.date).isSame(date, 'day');
      const sameDateForArrival = moment(departure.date).isSame(date, 'day');
      let start = slots.startTime;
      let end = slots.endTime;

      if (isEmpty(tripDetail)) {
        if (this.isDeparture() && sameDateForDeparture && arrival.hour) {
          end = moment(arrival.hour, 'HH:mm').format('HH:mm');
        }
        if (this.isArrival() && sameDateForArrival && departure.hour) {
          start = moment(departure.hour, 'HH:mm')
            .add(slots.step, 'minutes')
            .format('HH:mm');
        }
      } else if (this.isDeparture()) {
        end = moment(tripDetail.departureTime, 'DD/MM/YYYY HH:mm')
          .subtract(slots.step, 'minutes')
          .format('HH:mm');
      } else {
        const value = moment(tripDetail.arrivalTime, 'DD/MM/YYYY HH:mm');
        if (withPrevious) {
          value.subtract(slots.step, 'minutes');
        }
        start = value.format('HH:mm');
      }

      start = moment(start, 'HH:mm');
      end = moment(end, 'HH:mm');

      displayedSlots = getAvailableTimesFromSlots(slots, start, end);
    }

    return displayedSlots;
  }

  openModal = (ref) => {
    if (ref && ref.current) {
      ref.current.openModal();
    }
  };

  closeModal = (ref) => {
    if (ref && ref.current) {
      ref.current.closeModal();
    }
  };

  isDeparture() {
    const { prefix } = this.props;
    return prefix === 'start';
  }

  isArrival() {
    const { prefix } = this.props;
    return prefix === 'end';
  }

  render() {
    const {
      t,
      prefix,
      label,
      placeholder,
      date,
      hour,
      trip,
      tripDetail,
      forceValidation,
      isFetchingSlots,
      siteType,
    } = this.props;

    const times = this.getDisplayedSlots();

    // Checking selected hour is available
    const unavailableHours = times.filter((t) => t.isUnavailable).map((t) => t.value);

    const hasAvailableHours = times.filter((t) => !t.isUnavailable).length > 0;

    const hourValue = unavailableHours.indexOf(hour) === -1 ? hour : null;

    // Gestion des erreurs
    const errors = {
      success: false,
      warning: false,
      error: isErrorField(forceValidation, hourValue) || !hasAvailableHours,
      message: !hasAvailableHours ? t('booking-no-slot') : '',
    };

    // Si on est en mode "full booking"
    // et qu'un voyage a été sélectionné (et pas forcé),
    // alors on va vérifier si les infos renseignées par le client sont correctes
    if (this.isDeparture() && !trip.isForced && trip.isValid) {
      const selectedDate = moment(date);
      if (hour) {
        const [hours, minutes] = hour.split(':');
        selectedDate.set({ hours, minutes });
      }

      const tripDate = moment(tripDetail.departureTime, 'DD/MM/YYYY HH:mm');

      const warningDuration = MinWarningDuration[siteType];
      // S'il y a une heure de sélectionnée
      if (hour && hour.length > 0) {
        // alors on vérifie qu'elle est avant l'heure de départ du voyage sélectionné.
        if (selectedDate.isBefore(tripDate, 'minute')) {
          // S'il y a moins de x minutes
          // alors on informe qu'il peut y avoir des soucis
          if (selectedDate.diff(tripDate, 'minute') > -warningDuration) {
            errors.warning = true;
            errors.message = t('booking-trip_warning_hours', { n: warningDuration });
          } else {
            errors.success = true;
          }
        } else {
          errors.error = true;
        }
      }
    }

    if (this.isArrival() && hour !== undefined && hour.length > 0 && trip.isValid) {
      errors.success = true;
    }

    if (this.isArrival() && !trip.isForced && trip.isValid) {
      const selectedDate = moment(date);
      if (hour) {
        const [hours, minutes] = hour.split(':');
        selectedDate.set({ hours, minutes });
      }

      const tripDate = moment(tripDetail.arrivalTime, 'DD/MM/YYYY HH:mm');

      // S'il y a une heure de sélectionnée
      if (hour && hour.length > 0) {
        // alors on vérifie qu'elle est supérieur ou égal à l'heure de départ du voyage sélectionné.
        if (selectedDate.isSameOrAfter(tripDate)) {
          errors.success = true;
        } else {
          errors.error = true;
        }
      } else if (!hour && tripDetail && tripDetail.arrivalTime) {
        errors.error = true;
      }
    }

    let displaySelector = false;
    if (this.isArrival() && tripDetail && tripDetail.arrivalTime) {
      const [, tripTime] = tripDetail.arrivalTime.split(' ');
      displaySelector = unavailableHours.includes(tripTime);
    }

    const name = `${prefix}-time`;
    const fixedHour = this.isArrival() && !displaySelector && hasAvailableHours;
    const fixedHourClassName = trip.isValid ? 'success' : 'regular';

    const clsHourPicker = Classnames('HourPicker', {
      hidden: !trip.isValid && !trip.isForced && !trip.isUnknown,
    });

    const fullLabel = label ? t(label) : null;
    const fullPlaceholder = t(placeholder || 'hour');

    return (
      <div className={clsHourPicker}>
        {isFetchingSlots && !fixedHour && (
          <InputSelect
            id="loading"
            name="loading"
            label={fullLabel}
            placeholder={fullPlaceholder}
            loading
          />
        )}

        {fixedHour && (
          <div className={fixedHourClassName}>
            <Fixed dark label={fullLabel} value={hour} />
          </div>
        )}

        {!isFetchingSlots && !fixedHour && (
          <>
            <InputSelect
              data-cy={name}
              id={name}
              name={name}
              label={fullLabel}
              value={hourValue || ''}
              onChange={this.onHourChange}
              placeholder={fullPlaceholder}
              success={errors.success}
              warning={errors.warning}
              error={errors.error}
              message={errors.message}
              icon={<TimeIcon />}
            >
              {times &&
                times.map((time) => (
                  <option key={time.value} value={time.value} disabled={time.isUnavailable}>
                    {time.isUnavailable ? `${time.value} (${t('full')})` : time.value}
                  </option>
                ))}
            </InputSelect>
            <Modal
              id="ArrivalUnavailableModal"
              ref={this.unavailableModalRef}
              title={t('booking-unavailable-arrival-title')}
              content={`${t('booking-unavailable-arrival-content')}
                ${!times || times.length === 0 ? `\n${t('booking-unavailable-arrival-content-no-slots')}` : ''}`
              }
              primaryText={t('booking-unavailable-arrival-button')}
              onClickPrimary={() => this.closeModal(this.unavailableModalRef)}
            />
          </>
        )}
      </div>
    );
  }
}

const mstp = ({ trip, booking }, props) => ({
  departure: booking.departure,
  arrival: booking.arrival,
  isArrivalSlotAvailable: booking.isArrivalSlotAvailable,
  trip: trip[props.prefix],
});

const mdtp = (dispatch) => bindActionCreators({
  setArrivalSlotAvailable,
  setArrivalSlotUnAvailable,
  persistCart,
}, dispatch);

export default connect(mstp, mdtp)(withTranslation()(TripHourPicker));

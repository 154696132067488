import Moment from 'moment/moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const dateFormat = 'DD/MM/YYYY';
const hourFormat = 'HH:mm';

export const fromStringToMoment = (d) => moment(d, dateFormat, true);

export const dateAndHourToMoment = (d, h) => moment(`${d} ${h}`, `${dateFormat} ${hourFormat}`, true);
export const dateTimeToMoment = (dt) => moment(dt, `${dateFormat} ${hourFormat}`, true);
export const fromStringToDate = (d) => moment(d, dateFormat, true).toDate();
export const fromDateToString = (d: Date) => moment(d).format(dateFormat);

export const fromMomentToString = (m) => m.format(dateFormat);

/* @flow */

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

import CalendarIcon from '@bluevalet/react-icons/calendar';
import { Input } from '@bluevalet/common-ui';

import { isErrorField } from '../../booking/utils';
import { fromDateToString, fromStringToMoment } from '../../../utils/dates';


const getLang = (lang) => {
  switch (lang) {
    case 'fr': return 'fr-FR';
    case 'en': return 'en-GB';
    case 'es': return 'es-ES';
    case 'nl': return 'nl-NL';
    case 'pt': return 'pt-BR';
    case 'it': return 'it-IT';
    default: return 'en-GB';
  }
};

const pickerOptions = (i18n) => ({
  language: getLang(i18n.language),
  format: 'dd/mm/yyyy',
  startDate: new Date(),
  weekStart: 1,
  autoHide: true,
  startView: 0,
  endView: 0,
});

type DatePickerHTMLEvent = {
  date: Date,
};

type DayPickerProps = {
  i18n: any,
  t: (key: string) => string,
  prefix: String,
  date?: String,
  minDate?: String,
  maxDate?: String,
  label: string,
  forceValidation?: boolean,
  readOnly?: boolean,
  onDateChange: (value: String) => void,
};

class DayPicker extends PureComponent {
  static props: DayPickerProps;

  constructor(props) {
    super(props);
    this.refName = `${props.prefix}Input`;
    this[this.refName] = React.createRef();
  }

  componentDidMount() {
    const { date, minDate, maxDate, i18n, readOnly } = this.props;

    if (!readOnly) {
      const $picker = $(this[this.refName].current);
      this.createDatePicker($picker, i18n);
      this.setPickerDates($picker, date, minDate, maxDate);
    }
  }

  componentDidUpdate(prevProps) {
    const { date, minDate, maxDate, i18n, readOnly } = this.props;
    const $picker = $(this[this.refName].current);

    if (readOnly) {
      if (!prevProps.readOnly) {
        $picker.datepicker('destroy');
      }
    } else {
      if (prevProps.readOnly) {
        this.createDatePicker($picker, i18n);
      }
      this.setPickerDates($picker, date, minDate, maxDate);
    }
  }

  createDatePicker = ($picker, i18n) => {
    $picker.datepicker({
      ...pickerOptions(i18n),
      pick: this.onDatePickerChange,
    });
  }

  setPickerDates = ($picker, date, minDate, maxDate) => {
    $picker.datepicker('setDate', date, true);
    $picker.datepicker('setValue', date);

    if (minDate) {
      $picker.datepicker('setStartDate', minDate);
      $picker.datepicker('setViewDate', minDate);
    }

    if (maxDate) {
      $picker.datepicker('setEndDate', maxDate);
    }
  }

  onDatePickerChange = (event: DatePickerHTMLEvent) => {
    const { onDateChange } = this.props;
    onDateChange(fromDateToString(event.date));
  }

  onInputChange = ({ target: { value } }) => {
    const { minDate, maxDate, onDateChange } = this.props;
    const dateValue = fromStringToMoment(value);

    if (dateValue.isValid()
      && (!minDate || dateValue.isSameOrAfter(fromStringToMoment(minDate)))
      && (!maxDate || dateValue.isBefore(fromStringToMoment(maxDate)))) {
      onDateChange(value);
    }
  }

  render() {
    const {
      t,
      prefix,
      label,
      date,
      forceValidation,
      readOnly,
    } = this.props;

    const hasErrors = isErrorField(forceValidation, date);

    const inputProps = {
      success: false,
      error: hasErrors,
      message: hasErrors ? t('error-required') : '',
    };

    const dateName = `${prefix}Date`;

    return (
      <div className="DayPicker">
        <Input
          ref={this[this.refName]}
          data-cy={dateName}
          id={dateName}
          name={dateName}
          label={label}
          type="text"
          placeholder={t('date')}
          autoComplete="off"
          onChange={this.onInputChange}
          icon={<CalendarIcon />}
          inputMode="none"
          readOnly={readOnly}
          {...inputProps}
        />
      </div>
    );
  }
}

export default withTranslation()(DayPicker);

/* @flow */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { InputCode } from '@bluevalet/common-ui';

import { displayDecimal } from '../utils';

import type { OrderVoucher } from '../types';

type Props = {
  t: (key: string) => string,
  value?: string,
  status?: OrderVoucher,
  updateVoucher: (value: string) => void,
  isUpdatingVoucher?: boolean,
};

const isVoucherInError = (voucher?: OrderVoucher) => voucher && voucher.status !== 'Valid';
const isVoucherValid = (voucher?: OrderVoucher) => voucher && voucher.status === 'Valid';

const getVoucherErrorLabel = (t: (key: string) => string, status: string) => {
  switch (status) {
    case 'RequireAuthentication':
      return t('booking-voucher_error_require_auth');
    case 'AlreadyUsed':
      return t('booking-voucher_error_already_used');
    case 'NotApplicable':
      return t('booking-voucher_not_applicable');
    case 'NotFound':
    default:
      return t('booking-voucher_error_not_found');
  }
};

const displayVoucherError = (t: (key: string) => string, voucher: OrderVoucher) => {
  if (isVoucherInError(voucher)) {
    return <em>{getVoucherErrorLabel(t, voucher.status)}</em>;
  }

  return null;
};

const Voucher = ({ t, value, status, updateVoucher, isUpdatingVoucher }: Props) => {
  const [code, setCode] = useState(value || '');

  const isSuccess = value && value.length > 0 && isVoucherValid(status);
  const isFail = value && value.length > 0 && isVoucherInError(status);

  const onChange = (event: SyntheticEvent) => {
    setCode(event?.target?.value?.trim());
  };

  return (
    <div className="booking-voucher">
      <InputCode
        id="voucher"
        name="voucher"
        label={t('booking-voucher_label')}
        buttonLabel={t('booking-voucher_validate')}
        placeholder={t('booking-voucher_placeholder')}
        value={code}
        onChange={onChange}
        onClick={() => updateVoucher(code)}
        promo={!isUpdatingVoucher && isSuccess && displayDecimal(status.discount)}
        success={!isUpdatingVoucher && isSuccess}
        fail={!isUpdatingVoucher && isFail}
        isLoading={isUpdatingVoucher}
      />
      {!isUpdatingVoucher && status && value && value.length > 0 && displayVoucherError(t, status)}
    </div>
  );
};

export default withTranslation()(Voucher);

import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Switch } from '@bluevalet/common-ui';
import CloseIcon from '@bluevalet/react-icons/icon-close';
import { isGranted } from './service';


export default function CookieConsentModal({
  isOpen,
  validateChoice,
  onRequestClose,
  consent,
  url,
}) {
  const [analyticsConsentState, setAnalyticsConsentState] = useState(
    isGranted(consent?.details?.analyticsStorage),
  );
  const [pubConsentState, setPubConsentState] = useState(isGranted(consent?.details?.adStorage));

  const { t } = useTranslation();

  useEffect(() => {
    setAnalyticsConsentState(isGranted(consent?.details?.analyticsStorage));
    setPubConsentState(isGranted(consent?.details?.adStorage));
  }, [consent]);

  const modalStyle = {
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      maxHeight: '100%',
      zIndex: 17_000_002,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    content: {
      position: 'relative',
      top: 'auto',
      right: 'auto',
      left: 'auto',
      bottom: 'auto',
      width: 640,
      maxWidth: '100%',
      maxHeight: '100%',
      padding: '0',
      border: 'none',
      background: '#ffffff',
      boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.07)',
      borderRadius: '2px',
      overflowY: 'auto',
    },
  };

  const handleConsentValidation = () => {
    validateChoice({
      adStorage: pubConsentState,
      adUserData: pubConsentState,
      adPersonalization: pubConsentState,
      analyticsStorage: analyticsConsentState,
      functionalityStorage: false,
      personalizationStorage: false,
      securityStorage: false,
    });
    onRequestClose();
  };

  const CookieLink = ({ children }) => (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={url} target="_blank">{children}</a>
  );

  return (
    <ReactModal isOpen={isOpen} style={modalStyle} onRequestClose={onRequestClose}>
      <button type="button" id="CookieConsentModalCloseButton" onClick={onRequestClose}>
        <CloseIcon />
      </button>
      <div className="CookieConsentModalTitle">
        <p>{t('cookies-consent-modalTitle')}</p>
      </div>
      <div className="CookieConsentModalText">
        <p>
          <Trans t={t} i18nKey="cookies-consent-modalContentTitle" />
        </p>
        <p>
          <b>{t('cookies-consent-modalContentUsageTitle')}</b>
        </p>
        <ul>
          <li>{t('cookies-consent-modalContentUsageContent1')}</li>
          <li>{t('cookies-consent-modalContentUsageContent2')}</li>
        </ul>
        <p>
          <b>{t('cookies-consent-modalOptionsTitle')}</b>
        </p>
      </div>
      <div className="CookieConsentModalSwitchs">
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="analyticsConsentState">
            {t('cookies-consent-modalOptionsBasic')}
          </label>
          <Switch
            id="analyticsConsentState"
            name="analyticsConsentState"
            onChange={() => {
              setAnalyticsConsentState(!analyticsConsentState);
            }}
            checked={analyticsConsentState}
          />
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="pubConsentState">{t('cookies-consent-modalOptionsAds')}</label>
          <Switch
            id="pubConsentState"
            name="pubConsentState"
            onChange={() => {
              setPubConsentState(!pubConsentState);
            }}
            checked={pubConsentState}
          />
        </div>
      </div>
      <div className="CookieConsentModalInfos">
        <p>
          <Trans
            t={t}
            i18nKey="cookies-consent-modalCheckLegalNoticeText"
            components={[<CookieLink />]}
          />
        </p>
      </div>
      <div className="CookieConsentModalFooter">
        <Button
          label={t('cookies-consent-modalButtonLabel')}
          primary
          onClick={handleConsentValidation}
        />
      </div>
    </ReactModal>
  );
}

/* @flow */

import fetchUrl from '../../utils/fetch';

export function appendNewsletterToForm($form: any) {
  const $newsletter = $form.find('#NewsletterContainer');
  if ($newsletter && $newsletter.length) {
    return fetchUrl('/newsletter/status', {
      method: 'GET',
    })
      .then((res) => res.text())
      .then((html) => $newsletter.html(html))
      .catch(() => {
        /* Nothing to do in case Mailjet call failed) */
      });
  }
  return Promise.resolve();
}

import React, { PureComponent } from 'react';

import { TravelInformations } from '@bluevalet/common-ui';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { dateAndHourToMoment } from '../../../utils/dates';
import API from '../../common/api';
import { SiteType } from '../../booking/types';

export const DEPARTURE_PREFIX: string = 'departure';
export const ARRIVAL_PREFIX: string = 'arrival';

type TripNumberProps = {
  t: (key: string) => string,
  prefix: string,
  site: string,
  siteType: string,
  date: string,
  tripNumber?: string,
  tripDetails?: PropTypes.object,
  onTripNumberChange: (value?: string) => void,
  onTripDetailsChange: (value?: string) => void,
  readOnly: boolean,
};

class TripNumber extends PureComponent {
  static props: TripNumberProps;

  constructor(props) {
    super(props);
    this.state = {
      fetchAbortController: new AbortController(),
      trip: {
        isFetchingInformations: false,
        isValid: false,
        isForced: false,
        isUnknown: false,
      },
    };
  }

  componentDidMount() {
    const { prefix, site, date, tripNumber, tripDetails } = this.props;
    if (!tripDetails) {
      this.fetchTripInfos(prefix, site, date, tripNumber);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { prefix, site, date, tripNumber } = this.props;

    if (date !== prevProps.date) {
      this.fetchTripInfos(prefix, site, date, tripNumber);
    }
  }

  onTravelInformationsChange = (event) => {
    const { value } = event.target;
    const { prefix, site, date, onTripNumberChange } = this.props;
    const { fetchAbortController } = this.state;

    fetchAbortController.abort();
    this.setState({
      fetchAbortController: new AbortController(),
    }, () => {
      const { fetchAbortController } = this.state;
      const { signal } = fetchAbortController;

      onTripNumberChange(value);
      this.fetchTripInfos(prefix, site, date, value, signal);
    });
  }

  onClearTripNumber = () => {
    const { onTripNumberChange, onTripDetailsChange } = this.props;
    onTripNumberChange('');
    onTripDetailsChange();
  }

  getStatus = () => {
    const { tripNumber } = this.props;
    const { trip: { isFetchingInformations } } = this.state;
    if (isFetchingInformations) {
      return 'loading';
    }
    if (tripNumber !== '') {
      return 'loaded';
    }
    return '';
  }

  getInputProps = () => {
    const { site, date, hour, readOnly } = this.props;

    return {
      readOnly,
      disabled: !site || !date || dateAndHourToMoment(date, hour).isBefore(),
      success: false, // to prevent green border
      error: false, // to prevent red border
      message: '', // error message
    };
  }

  static getSiteLabel(result, prefix) {
    if (result) {
      const siteType = result?.type.toLowerCase();
      if (prefix === DEPARTURE_PREFIX) {
        if (siteType === 'flight') {
          return result.departureAirport;
        }
        if (siteType === 'train') {
          return result.departureStation;
        }
      }
      if (prefix === ARRIVAL_PREFIX) {
        if (siteType === 'flight') {
          return result.arrivalAirport;
        }
        if (siteType === 'train') {
          return result.arrivalStation;
        }
      }
    }
    return '';
  }

  static getTravelInformationsLabelProperty(prefix, siteKind) {
    let direction;
    let type;
    switch (prefix) {
      case DEPARTURE_PREFIX: direction = 'start'; break;
      case ARRIVAL_PREFIX: direction = 'end'; break;
      default: return null;
    }
    switch (siteKind) {
      case SiteType.Airport: type = 'flight'; break;
      case SiteType.Station: type = 'train'; break;
      default: type = 'gen';
    }
    return `booking-trip_${direction}_${type}_number`;
  }

  static getTravelInformationsName(prefix) {
    if (prefix === DEPARTURE_PREFIX) {
      return 'startTripNumber';
    }
    if (prefix === ARRIVAL_PREFIX) {
      return 'endTripNumber';
    }
    return null;
  }

  fetchTripInfos = async(prefix, site, date, value, signal) => {
    const { t, onTripDetailsChange } = this.props;
    if (value && value.toLowerCase() !== t('booking-trip_unknown_number_sel').toLowerCase()) {
      this.setState({ trip: { isFetchingInformations: true } });
      const results = await API.getTripInfos(prefix, site, date, value, signal)
        .catch(() => null);
      this.setState({ trip: { isFetchingInformations: false } });
      onTripDetailsChange(results);
    } else {
      onTripDetailsChange();
    }
  }

  render() {
    const { t, prefix, tripNumber, tripDetails, siteType } = this.props;

    return (
      <div className="trip-informations">
        <TravelInformations
          type={prefix}
          name={TripNumber.getTravelInformationsName(prefix)}
          label={`${t(TripNumber.getTravelInformationsLabelProperty(prefix, siteType))} *`}
          site={TripNumber.getSiteLabel(tripDetails, prefix)}
          message={t('booking-trip_travel_not_found')}
          value={tripNumber}
          onChange={this.onTravelInformationsChange}
          onClearValue={this.onClearTripNumber}
          status={this.getStatus()}
          results={tripDetails ? [tripDetails] : []}
          inputProps={this.getInputProps()}
          lang={i18next.language}
        />
      </div>
    );
  }
}

export default withTranslation()(TripNumber);
